import { Trans } from '@lingui/react';
import React, { useEffect, useRef, useState } from 'react';
import './YourDetailMobile.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { IYourDetails } from './interface';
import { isEmail, isValidFullName, validateInputsTours } from './utils';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ICreateMeeting } from '../../services/Interfaces';

interface IProps {
  createMeetingAsyncMobile: () => void;
  userInput?: IYourDetails;
  setUserInput: (state: IYourDetails) => void;
  meeting?: ICreateMeeting;
}

const YourDetailMobile: React.FC<IProps> = ({ createMeetingAsyncMobile, userInput, setUserInput, meeting }) => {
  const [validated, setValidated] = useState<boolean>(true);
  const [match, setMatch] = useState<boolean>(false);
  const [dial, setDial] = useState<boolean>(false);

  const captchaRef = useRef<any>(null);

  useEffect(() => {
    if (validateInputsTours(userInput)) {
      setValidated(true);
    } else {
      setValidated(false);
    }
    // Validate inputs
  }, [userInput]);

  const getInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput({ ...userInput, [e.target.name]: e.target.value });
  };

  const setToken = (token: string | null) => {
    if (token) {
      setUserInput({ ...userInput, token: token });
    }
  };
  return (
    <div className='tour-detail-container-mobile'>
      <div className='tour-detail-text-area-mobile'>
        <p className='text-tour-detail-mobile'>
          <Trans id='Enter your details' />
        </p>
        <p className='subtext-tour-detail-mobile'>
          <Trans id='Fill out the fields below to join the live sharing' />
        </p>
      </div>
      <div className='tour-detail-input-area-mobile'>
        <div className='create-tour-input-mobile'>
          <div
            className={`create-tour-input-labels ${
              userInput?.firstName === undefined || userInput.firstName === ''
                ? 'create-tour-input-labels '
                : isValidFullName(userInput?.firstName || '')
                ? ''
                : 'create-tour-input-labels-invalid'
            }`}
          >
            <Trans id='First Name' render={({ translation }) => <label className='create-tour-labels'>{translation}</label>} />
          </div>
          <input
            placeholder='Olivia'
            id='firstName'
            name='firstName'
            value={userInput?.firstName || ''}
            className={`general-field-input-text-mobile ${
              userInput?.firstName === undefined || userInput.firstName === ''
                ? 'general-field-input-text-mobile'
                : isValidFullName(userInput?.firstName || '')
                ? 'valid-input'
                : 'invalid-input'
            }`}
            type='text'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => getInput(e)}
          />
        </div>
        <div className='create-tour-input-mobile'>
          <div
            className={`create-tour-input-labels ${
              userInput?.lastName === undefined || userInput.lastName === ''
                ? 'create-tour-input-labels '
                : isValidFullName(userInput?.lastName || '')
                ? ''
                : 'create-tour-input-labels-invalid'
            }`}
          >
            <Trans id='Last Name' render={({ translation }) => <label className='create-tour-labels'>{translation}</label>} />
          </div>
          <input
            style={{ marginBottom: '24px' }}
            placeholder='Rhye'
            id='lastName'
            name='lastName'
            value={userInput?.lastName || ''}
            className={`general-field-input-text-mobile ${
              userInput?.lastName === undefined || userInput.lastName === ''
                ? 'general-field-input-text-mobile'
                : isValidFullName(userInput?.lastName || '')
                ? 'valid-input'
                : 'invalid-input'
            }`}
            type='text'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => getInput(e)}
          />
        </div>
      </div>
      <div className='tour-detail-input-area-mobile'>
        <div className='create-tour-input-mobile'>
          <div
            className={`create-tour-input-labels ${
              userInput?.email === undefined || userInput.email === ''
                ? 'create-tour-input-labels '
                : isEmail(userInput?.email || '')
                ? ''
                : 'create-tour-input-labels-invalid'
            }`}
          >
            <Trans id='Email' render={({ translation }) => <label className='create-tour-labels'>{translation}</label>} />
          </div>
          <input
            placeholder='olivia.rhye@gmail.com'
            value={userInput?.email || ''}
            id='email'
            name='email'
            className={`general-field-input-text-mobile  ${
              userInput?.email === undefined || userInput.email === ''
                ? 'general-field-input-text-mobile'
                : isEmail(userInput?.email || '')
                ? 'valid-input'
                : 'invalid-input'
            }`}
            type='text'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => getInput(e)}
          />
        </div>

        <div className='create-tour-input-mobile'>
          <div
            className={`${
              userInput?.phoneNumber === undefined || userInput.phoneNumber === '' || dial || match
                ? 'create-tour-input-labels '
                : 'create-tour-input-labels-invalid'
            }`}
          >
            <Trans id='Phone' render={({ translation }) => <label className='create-tour-labels'>{translation}</label>} />
          </div>
          <PhoneInput
            enableSearch={true}
            enableLongNumbers={true}
            disableSearchIcon={true}
            country={'gb'}
            value={userInput?.phoneNumber}
            inputClass={`${
              match
                ? 'input-phone-validated-mobile'
                : userInput?.phoneNumber === '' || dial
                ? 'input-phone-mobile'
                : 'invalid-input-phone-mobile'
            }`}
            isValid={(value: string, country: any) => {
              const specialChars = /[`!@#$%^&*() _+\-=[\]{};':"\\|,<>/?~]/;
              const formatCountry = country.format.slice(1).split(specialChars).join('');
              if (country.dialCode.length === value.length || value.length < 3) {
                setMatch(false);
                setDial(true);
                return true;
              } else if (formatCountry.length !== value.length) {
                setMatch(false);
                setDial(false);
                return false;
              } else {
                setMatch(true);
                setDial(false);
                return true;
              }
            }}
            containerClass={`${match ? 'valid-input-phone' : userInput?.phoneNumber === '' || dial ? 'default-input-phone' : ''}`}
            dropdownClass='dropdown-phone'
            buttonClass='button-flag'
            searchPlaceholder='Search country'
            onChange={value => setUserInput({ ...userInput, phoneNumber: value })}
          />
        </div>
      </div>
      <div className='container-captcha-mobile'>
        <ReCAPTCHA sitekey={window.ENV.REACT_APP_SITE_KEY || process.env.REACT_APP_SITE_KEY} ref={captchaRef} onChange={setToken} />
      </div>
      <div className='container-button-next-mobile'>
        <div className={validated && match ? 'button-next-mobile' : 'button-next-disable-mobile'} onClick={createMeetingAsyncMobile}>
          <Trans id='Next' />
        </div>
      </div>
    </div>
  );
};

export default YourDetailMobile;
