import React from 'react';
import './BadRequest.scss';
import pageNotFound from '../../images/empty-state/page-not-found.svg';
import { Trans } from '@lingui/react';

/**
 * Bad request component
 * @returns BadRequest component
 * @category Component
 */
const BadRequest = (): JSX.Element => {
  return (
    <div className={'container not-found-layout'}>
      <div className='container-not-found'>
        <div className='container-texte'>
          <h1 className='not-found-title'>
            <Trans id='Ooops...something went wrong' />
          </h1>
          <p className='not-found-text'>
            <Trans id='The Page you are looking for does not exist or an error occurred' />
          </p>
        </div>
        <div className='container-img'>
          <img className='not-found-img' src={pageNotFound} alt='404 error state'></img>
        </div>
      </div>
    </div>
  );
};

export default BadRequest;
