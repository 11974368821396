import { ClientApi } from '@vva/front';
import { IYourDetails } from '../components/modal/interface';
import { ICreateMeeting, IOrganiser, ISendEmailResponse } from './Interfaces';

const clientApi = new ClientApi();

export const createMeeting = async (tourUrl: string, tourName: string, tourOwner: string): Promise<ICreateMeeting> => {
	const meetingResponse: any = await clientApi.postData(
		`${window.ENV.REACT_APP_LIVE_API_URL || process.env.REACT_APP_LIVE_API_URL}/meeting`, { tourUrl, tourName, tourOwner }
	);
	return meetingResponse;
};


export const createOrganiser = async (meetingCode: string, body: IYourDetails): Promise<IOrganiser> => {

	const organiserResponse: any = await clientApi.postData(
		`${window.ENV.REACT_APP_LIVE_API_URL || process.env.REACT_APP_LIVE_API_URL}/meeting/${meetingCode}/organiser`, body
	);
	return organiserResponse;
}

export const resentJoinMeetingEmail = async (meetingCode: string, userId: string): Promise<ICreateMeeting> => {
	const resentResponseEmail: any = await clientApi.postData(
		`${window.ENV.REACT_APP_LIVE_API_URL || process.env.REACT_APP_LIVE_API_URL}/meeting/invite/${userId}`, { meetingCode: meetingCode }
	);
	return resentResponseEmail;
}

export const sendInviteeEmail = async (meetingCode: string, email: string): Promise<ISendEmailResponse> => {
	const sendEmailResponse: any = await clientApi.postData(
		`${window.ENV.REACT_APP_LIVE_API_URL || process.env.REACT_APP_LIVE_API_URL}/meeting/invite`, { meetingCode: meetingCode, email: email }
	);
	return sendEmailResponse;
}

