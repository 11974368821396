import React from 'react';
import ReactDOM from 'react-dom';
import './style.scss';

interface Props {
  visible: boolean;
  isHotspotUrl: boolean;
  childComp?: JSX.Element | null;
  text: string;
  tittle: string;
  onAccept?: () => void;
  onCancel: () => void;
  modalType: string;
  url?: string;
  enableButtonGroup: boolean;
}

const ModalHotspot = (props: Props) => {
  const { childComp } = props;

  const getClassName = () => {
    switch (props.modalType) {
      case 'video':
        return 'modal-video-dimensions';
      case 'media':
        return 'modal-media-dimensions';
      case 'text':
        return 'modal-text-dimensions';
      default:
    }
  };

  return props.visible
    ? ReactDOM.createPortal(
        <div className='use-modal-hotspot'>
          <div className={`modal-hotspot-pop ${getClassName()}`} role='dialog' aria-modal='true'>
            <div className='modal-hotspot-title'>
              <div className='close-btn-hotspot-top' onClick={props.onCancel}>
                <i className='close-btn-hotspot-top-icon' />
              </div>
            </div>
            <div className='modal-hotspot-content'>
              {childComp ? <div className='modal-hotspot-child-element'> {childComp}</div> : null}
              <div className='modal-content-hotspot-text'>
                <div className='modal-content-text-first-row'>
                  <p className='modal-content-modal-title'>{props.tittle}</p>
                </div>
                <div className='modal-content-text-second-row'>
                  {props.text ? <p className='main-hotspot-text'>{props.text}</p> : null}
                  {props.url ? (
                    <a className='link-text' target='_blank' href={props.url} rel='noreferrer'>
                      {props.url}
                    </a>
                  ) : null}
                </div>
              </div>
              {props.enableButtonGroup ? (
                <div className='btn-hotspot-group'>
                  <button className='btn-hotspot-accept' type='button' onClick={props.onAccept}>
                    Accept
                  </button>
                  <button className='btn-hotspot-cancel' type='button' onClick={props.onCancel}>
                    Close
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div className='modal-hotspot-overlay'></div>
        </div>,
        document.body
      )
    : null;
};

export default ModalHotspot;
