import React, { useState } from 'react';
import Confirmation from '../../images/modal/confirmation.svg';
import './ConfirmationSentMobile.scss';
import { Trans } from '@lingui/react';
import { resentJoinMeetingEmail } from '../../services/liveMeetingServices';
import { useLocation } from 'react-router-dom';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notification/notification';

const ConfirmationSentMobile = () => {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [clicked, setClicked] = useState<boolean>(false);
  const location = useLocation();
  const { userId, meetingCode, email } = location.state;

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    setTypeNotification(type);
    setNotificationMessage(message || 'There was an error please try again');
    setShowNotification(true);
  };

  const resentJoinMeetingEmailAsync = async () => {
    try {
      await resentJoinMeetingEmail(meetingCode, userId);
      toggleNotification(EnumNotificationType.Success, 'Email been succesfully resend');
      setClicked(true);
    } catch (error) {
      console.log(error);
      toggleNotification(EnumNotificationType.Error, 'Ooops something went wrong - give it an other go');
    }
  };

  return (
    <>
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
        />
      )}
      <div className='container-background-mobile'>
        <div className='confirmation-text-container-mobile'>
          <div className='title-confirmation-mobile'>
            <Trans id='The confirmation email has just been sent!' />
          </div>
          <div className='first-subtext-mobile'>
            <Trans id='We have sent an email to access the Live-Sharing to:' />
          </div>
          <div className='email-confirmation-mobile'>{email}</div>
          <div className='second-subtext-mobile'>
            <Trans id='Click the button in the email to start the live sharing of this property ' />
          </div>
          <img className='confirmation-img-mobile' src={Confirmation} alt='illustration' />
          <div className='havent-received-mobile'>
            <Trans id='Haven’t received it? Click below to resend it' />
          </div>
          {!clicked ? (
            <div className='button-resend-the-email-mobile' onClick={() => resentJoinMeetingEmailAsync()}>
              <Trans id='Resend the email' />
            </div>
          ) : (
            <div className='button-pressed-resend-mobile'>
              <Trans id='Sent' />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ConfirmationSentMobile;
