import React, { useEffect, useState } from 'react';
import { useMobileOrientation } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICreateMeeting } from '../../services/Interfaces';
import { createMeeting } from '../../services/liveMeetingServices';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notification/notification';
import { IUserInvite, IYourDetails } from './interface';
import InviteUserMobile from './InviteUserMobile';
import { TabStateEnum } from './ModalShare';
import './ModalShareMobile.scss';
import WrongPosition from './WrongPosition';
import YourDetailMobile from './yourDetailMobile';

const ModalShareMobile = () => {
  const [tabState, setTabsState] = useState<TabStateEnum>(TabStateEnum.YourDetails);
  const [userInput, setUserInput] = useState<IYourDetails>();
  const [inviteUser, setInviteUser] = useState<IUserInvite>();
  const [meeting, setMeeting] = useState<ICreateMeeting>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [wrongPosition, setWrongPosition] = useState<boolean>(false);
  const currentUrl = window.location.href;
  const navigate = useNavigate();
  const mobileOrientation = useMobileOrientation();
  const location = useLocation();
  const { tourName, tourOwner } = location.state;

  useEffect(() => {
    if (mobileOrientation.isLandscape) {
      setWrongPosition(true);
    }
    if (mobileOrientation.isPortrait) {
      setWrongPosition(false);
    }
  }, [mobileOrientation, navigate, currentUrl]);

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    setTypeNotification(type);
    setNotificationMessage(message || 'There was an error please try again');
    setShowNotification(true);
  };

  const createMeetingAsyncMobile = async () => {
    if (!meeting) {
      try {
        const meetingUrl = window.location.href;
        const newMeeting = await createMeeting(meetingUrl, tourName, tourOwner);
        setMeeting(newMeeting);
        setTabsState(TabStateEnum.SendInvite);
      } catch (error) {
        console.log(error);
        // toggleNotification(EnumNotificationType.Error, 'Ooops something went wrong - give it an other go');
      }
    } else {
      setTabsState(TabStateEnum.SendInvite);
    }
  };

  return (
    <>
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
        />
      )}
      {!wrongPosition && (
        <div className='container-modal-mobile'>
          <div className='modal-body-mobile'>
            <div
              style={{
                opacity: tabState === TabStateEnum.YourDetails ? 1 : 0,
                display: tabState === TabStateEnum.YourDetails ? '' : 'none',
              }}
            >
              <YourDetailMobile
                createMeetingAsyncMobile={createMeetingAsyncMobile}
                meeting={meeting}
                userInput={userInput}
                setUserInput={setUserInput}
              />
            </div>

            <div
              style={{
                opacity: tabState === TabStateEnum.SendInvite ? 1 : 0,
                display: tabState === TabStateEnum.SendInvite ? '' : 'none',
              }}
            >
              <InviteUserMobile
                toggleNotification={toggleNotification}
                meeting={meeting}
                userInput={userInput}
                inviteUser={inviteUser}
                setInviteUser={setInviteUser}
              />
            </div>
          </div>
        </div>
      )}
      {wrongPosition && <WrongPosition />}
    </>
  );
};
export default ModalShareMobile;
