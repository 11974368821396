import { useState, useEffect } from 'react';

interface IProps {
  email?: string;
  fullName?: string;
  phoneNumber?: string;
  fullNameValidation?: boolean;
  phoneNumberValidation?: boolean;
  emailValidation?: boolean;
}

export const useLeadsGenerationValidation = (props: IProps) => {
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [validFullName, setValidFullName] = useState<boolean>(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);

  useEffect(() => {
    //Regex objects
    //email

    const emailRegex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    //name
    const fullNameRegex = new RegExp(/^[a-zA-Z_ ]{2,}$/);

    // phone number
    const phoneNumberRegex = new RegExp(/^((\+)[0-9]{2}|0)?[0-9]{10}$/);

    // Email Validation
    props.emailValidation && props.email && setValidEmail(emailRegex.test(props.email));

    // Full Name validation
    props.fullNameValidation && props.fullName && setValidFullName(fullNameRegex.test(props.fullName));

    // Phone Number validation
    props.phoneNumberValidation && props.phoneNumber && setValidPhoneNumber(phoneNumberRegex.test(props.phoneNumber));
    // eslint-disable-next-line
  }, [props.fullName, props.email, props.phoneNumber]);

  return [validEmail, validFullName, validPhoneNumber];
};
