import { IViewerPannellumFloorPlanHotspot } from '../Viewer/Interfaces';

/**
 * The conditional type, specifying the type of data depending on the action type
 */
type Action =
	/** Append one or many item(s) to state*/
	| { type: 'ADD_ITEM'; item: IViewerPannellumFloorPlanHotspot }
	/** Remove one or many item(s) from state */
	| { type: 'REMOVE_ITEM'; id: string }
	/** Replace one or many item(s) from state if present */
	| { type: 'UPDATE_ITEM'; id: string; item: IViewerPannellumFloorPlanHotspot }
	/** Initialize with the initial items */
	| { type: 'ADD_INITIAL'; items: Array<IViewerPannellumFloorPlanHotspot> }
	/** Reset the whole state */
	| { type: 'RESET' }
	| { type: 'UPDATE_ITEMS'; id: string; item: IViewerPannellumFloorPlanHotspot };

/**
 * State interface
 */
interface State {
	data: IViewerPannellumFloorPlanHotspot[];
}

/**
 * @typeparam `T` type of the reducer state
 * @param {keyof T} key value of `U`
 * @returns {Reducer} React reducer for a stateful list of `T`
 *
 * Can be initiated like this
 * `createListReducer<Entity,id>()`
 * Where `Entity` is the type of the list and `"id"` is a property key on the type
 * that is to be used to find index in the list
 */
export default () =>
	(state: State, action: Action): State => {
		switch (action.type) {
			case 'ADD_ITEM': {
				return {
					...state,
					data: [...state.data, action.item]
				};
			}
			case 'REMOVE_ITEM': {
				const existingRemove = state.data.find((item: IViewerPannellumFloorPlanHotspot) => item.id === action.id);
				if (existingRemove) {
					const array = state.data.filter((item: IViewerPannellumFloorPlanHotspot) => item.id !== action.id);
					const newData = array;
					return {
						...state,
						data: newData
					};
				}
				return {
					...state
				};
			}
			case 'UPDATE_ITEM': {
				const elementsIndex = state.data.findIndex((item: IViewerPannellumFloorPlanHotspot) => item.id == action.id);
				const newArray = state.data;
				newArray[elementsIndex] = action.item;
				return {
					...state,
					data: newArray
				};
			}
			case 'UPDATE_ITEMS': {
				const updatedItems = state.data.map((item: IViewerPannellumFloorPlanHotspot) => {
					if (item.id === action.id) {
						item.isActive = true;
						return item;
					} else {
						item.isActive = false;
						return item;
					}
				});
				return {
					...state,
					data: updatedItems
				};
			}
			case 'ADD_INITIAL': {
				return {
					...state,
					data: action.items
				};
			}
			case 'RESET': {
				return {
					...state,
					data: []
				};
			}
			default:
				throw new Error('Action not supported');
		}
	};
