import React from 'react';
import './BadRequest.scss';
import pageNotFound from '../../images/empty-state/empty-tour.svg';
import { Trans } from '@lingui/react';

/**
 * Bad request component
 * @returns BadRequest component
 * @category Component
 */
const EmptyTour = (): JSX.Element => {
  return (
      <div className='container-not-found'>
        <div className='container-texte' style={{ width: '60%' }}>
          <h1 className='not-found-title'>
            <Trans id='Ooops...something' />
            <br />
            <Trans id=' went wrong' />
          </h1>
          <div className='subtext'>
          <p className='not-found-text'>
            <Trans id='This virtual tour does ' />
        
          <br />
            <Trans id=' not contain any images.' />
            </p>
          </div>
        </div>
        <div className='container-img'>
          <img className='empty-tour-img' src={pageNotFound} alt='404 error state'></img>
        </div>
      </div>
  );
};

export default EmptyTour;
