import React, { useEffect, useRef, useState } from 'react';
import { ViewerImplementation } from '../components/Viewer/ViewerImplementation';
import { ReportCustomEvent, ReportPageView, UserGoogleAnalyticsInit } from '../GoogleAnalyticsConfig';
import { mapHotspotValues } from '@vva/pannellum-viewer';
import TourNotFound from '../components/TourNotFound/TourNotFound';
import BadRequest from '../components/BadRequest/BadRequest';
import { EnumCategory, EnumEvent, EnumPagesTitles } from '../GoogleAnalyticsTrackList';
import {
  IBranding,
  ILeadGenerationConfig,
  IPanoramaVersionsList,
  IRoomsName,
  IViewerDataFloorPlan,
  IViewerPannellumData,
  IViewerPannellumFirstPanorama,
  IViewerPannellumFloorPlan,
  IViewerPannellumPanoramas,
  IViewerPannellumPanoramaSound,
  IViewerPannellumThumbnail,
} from '../components/Viewer/Interfaces';

type tourInformation = {
  name: string;
  latitude: number;
  longitude: number;
  id: string;
  userId: string;
  isAutoRotateEnable: boolean;
};

interface Props {
  tourData: IViewerPannellumData;
  ratingPermission: boolean;
  ratingToken: string;
}

const ProtectedViewerPage = (props: Props): JSX.Element => {
  // Component ref
  const componentIsMounted = useRef(true);

  // Authentication
  const [authentication, setAuthentication] = useState(true);

  // Tour components
  const [tour, setTour] = useState<IViewerPannellumPanoramas>();
  const [sounds, setSounds] = useState<IViewerPannellumPanoramaSound[]>([]);
  const [thumbnails, setThumbnails] = useState<IViewerPannellumThumbnail[]>([]);
  const [floorPlan, setFloorPlan] = useState<IViewerPannellumFloorPlan[]>([]);
  const [firstPanorama, setFirstPanorama] = useState<IViewerPannellumFirstPanorama>();
  const [tourInformation, setTourInformation] = useState<tourInformation>();
  const [roomNames, setRoomNames] = useState<IRoomsName[]>([]);
  const [leadGeneration, setLeadGeneration] = useState<ILeadGenerationConfig>();
  const [floorPlanData, setFloorPlanData] = useState<IViewerDataFloorPlan[]>([]);
  const [panoramaVersions, setPanoramaVersions] = useState<IPanoramaVersionsList>();

  // Rating component
  const [isRating, setIsRating] = useState(false);

  // Error states
  const [isBadRequest, setIsBadRequest] = useState(false);
  // eslint-disable-next-line
  const [NotFound, setNotFound] = useState(false);

  const [liveSharingOption, setLiveSharingOption] = useState(false);
  const [liveSharingName, setLiveSharingName] = useState<string>();
  const [liveSharingTourOwner, setLiveSharingTourOwner] = useState<string>();
  const [brandingOptions, setBrandingOptions] = useState<IBranding>();

  /**
   * Google analytics
   */
  useEffect(() => {
    // componentDidMount
    //Google report page
    ReportPageView(EnumPagesTitles.TourProtected);

    // componentWillUnmount
    return () => {
      //
    };
  }, []);

  /**
   * Component did mount
   */
  useEffect(() => {
    // each useEffect can return a cleanup function
    initializeComponent();
    return () => {
      componentIsMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  /**
   * Update tour meta tag description name
   * @param name
   */
  const updateDescriptionTag = (name: string) => {
    if (name !== '') {
      var element = document.querySelector('meta[name="description"]');
      element && element.setAttribute('content', name);
    }
  };

  /**
   * Initialize component from service response
   */
  const initializeComponent = () => {
    if (props.tourData) {
      const {
        firstPanorama,
        panoramas,
        sounds,
        thumbnails,
        floorplans,
        dataFloorPlan,
        isAutoRotateEnable,
        latitude,
        longitude,
        name,
        id,
        roomNamesMap,
        leadGeneration,
        userId,
        googleAnalyticsId,
        panoramaVersions,
        branding,
        liveSharingData,
      } = props.tourData;

      if (googleAnalyticsId !== '' && typeof googleAnalyticsId === 'string') {
        // Report event to google analytics
        ReportCustomEvent(EnumCategory.UserOwnAnalytics, EnumEvent.InitiateTourAnalyticsPrivately);

        UserGoogleAnalyticsInit(googleAnalyticsId);
      }

      // Set tour metadata
      updateDescriptionTag(name);

      // Set tour general information
      setTourInformation({ name, latitude, longitude, id, userId, isAutoRotateEnable });

      // Set floor plan with corresponding panorama
      setFloorPlanData(dataFloorPlan);

      // Panorama versions
      setPanoramaVersions(panoramaVersions);

      // Set floor plans
      setFloorPlan(floorplans);

      // Set Tour
      const panoramasMapped = mapHotspotValues(panoramas);
      setTour(panoramasMapped);

      if (sounds && sounds.length > 0) {
        setSounds(sounds);
      }

      // Set Thumbnails
      setThumbnails(thumbnails);

      // Set first panorama
      setFirstPanorama(firstPanorama);

      // Set Room names
      setRoomNames(roomNamesMap);

      // Set Lead generation
      setLeadGeneration(leadGeneration);

      setLiveSharingOption(liveSharingData.liveSharing);
      setLiveSharingName(liveSharingData.name);
      setLiveSharingTourOwner(liveSharingData.tourOwner);

      if (branding) {
        setBrandingOptions(branding);
      }

      // Set Rating permission
      setIsRating(props.ratingPermission);
    } else {
      setAuthentication(false);
      if (props.tourData) {
        setIsBadRequest(true);
      }
    }
  };

  /**
   * Handle service response
   * @returns page destination
   */
  const handleError = () => {
    if (isBadRequest) {
      return <TourNotFound />;
    }
    if (NotFound) {
      return <BadRequest />;
    }
  };

  return (
    <React.Fragment>
      {authentication ? (
        <ViewerImplementation
          tour={tour!}
          sounds={sounds}
          thumbnails={thumbnails}
          firstPanorama={firstPanorama!}
          floorPlan={floorPlan}
          floorPlanData={floorPlanData}
          tourInformation={tourInformation}
          roomNames={roomNames}
          leadGeneration={leadGeneration}
          ratingPermission={isRating}
          ratingToken={props.ratingToken}
          panoramaVersions={panoramaVersions}
          liveSharingOption={liveSharingOption}
          liveSharingName={liveSharingName}
          liveSharingTourOwner={liveSharingTourOwner}
          brandingOptions={brandingOptions}
        />
      ) : (
        handleError()
      )}
    </React.Fragment>
  );
};
export default ProtectedViewerPage;
