import React from 'react';
import ReactDOM from "react-dom/client";
import './index.scss';
import App from './App';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { BrowserRouter } from 'react-router-dom';
import { detect, fromNavigator, fromStorage } from '@lingui/detect-locale';
import { setLanguageFromCode } from './utils/lingui-utils/lingui-utils';

const DEFAULT_FALLBACK = () => 'en';

const result = detect(fromStorage('Language'), fromNavigator(), DEFAULT_FALLBACK) || '';

const locales: any = setLanguageFromCode(result);

// Language configuration
i18n.load(locales.code, locales.messages);
i18n.loadLocaleData(locales.code, { plurals: locales.plurals });
i18n.activate(locales.code);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <I18nProvider i18n={i18n}>
      <App />
    </I18nProvider>
  </BrowserRouter>,
);



