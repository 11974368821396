export const getFormattedDate = (date: Date) => {
	const panoramaUpdatedAt = new Date(date);
	const formattedDate = `${panoramaUpdatedAt.getHours() % 12}:${panoramaUpdatedAt.getMinutes()} ${panoramaUpdatedAt.getHours() >= 12 ? 'PM' : 'AM'
		}`;
	return formattedDate;
}

export const getDateNoTime = (date: Date) => {
	const panoramaUpdatedAt = new Date(date);
	return `${panoramaUpdatedAt.getDate()}/${panoramaUpdatedAt.getMonth()}/${panoramaUpdatedAt.getFullYear()}`;
}