import React, { MutableRefObject, useEffect, useRef } from 'react';
import './googleMap.scss';

interface Props {
  latitude: number;
  longitude: number;
  zoom: number;
  isStreetViewActive: boolean;
}

interface ICoordinates {
  lat: number;
  lng: number;
}

let panorama: google.maps.StreetViewPanorama;

let map: google.maps.Map;

function initMap(mapRef: MutableRefObject<HTMLDivElement>, center: ICoordinates): void {
  map = new google.maps.Map(mapRef.current as HTMLElement, {
    center: center,
    zoom: 19,
    panControl: false,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  });

  const marker = new google.maps.Marker({
    position: center,
  });

  marker.setMap(map);

  panorama = map.getStreetView()!; // TODO fix type
  panorama.setPosition(center);
  panorama.setPov(
    /** @type {google.maps.StreetViewPov} */ {
      heading: 34,
      pitch: 10,
    },
  );

  panorama.setValues({
    fullscreenControl: false,
    zoomControl: false,
    addressControl: false,
    panControl: false,
    enableCloseButton: false,
  });
}

function toggleStreetView(state: boolean, center: ICoordinates): void {
  if (state === false) {
    panorama.setVisible(true);
  } else {
    panorama.setVisible(false);
    map && map.panTo(center);
  }
}

export const GoogleMap = (props: Props): JSX.Element => {
  const mapRef = useRef() as MutableRefObject<HTMLDivElement>;
  // Coordinates
  const center = { lat: props.latitude, lng: props.longitude };

  useEffect(() => {
    initMap(mapRef, center);
    if (props.isStreetViewActive === true) {
      panorama && panorama.setVisible(true);
    } else {
      panorama && panorama.setVisible(false);
      map && map.panTo(center);
      map && map.setZoom(18);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    toggleStreetView(props.isStreetViewActive, center);
    // eslint-disable-next-line
  }, [props.isStreetViewActive]);

  return (
    <div className='map-container'>
      <div id='googleMap' className='map-ref' ref={mapRef} />
    </div>
  );
};
