import React, { useEffect, useState, useRef, MutableRefObject } from 'react';
import { Viewer } from '@vva/pannellum-viewer';
import { IViewerConfig } from '@vva/pannellum-viewer/lib/interfaces/IViewerConfig';
import FloorPlan from '../FloorPlan/FloorPlan';
import Toolbar from '../Toolbar/Toolbar';
import ControlsBar from '../ControlsBar/ControlsBar';
import { AddressBar } from '../AddressBar/AddressBar';
import { ValidationUtils } from '../../utils/validation-utils/validation-utils';
import useModal from '../../utils/custom-hooks/useModal/useModal';
import Modal from '../../utils/custom-hooks/useModal/Modal';
import LeadGenerationForm from '../LeadGenerationForm/LeadGenerationForm';
import { GoogleMap } from '../../utils/google-utils/google-map/googleMap';
import './ViewerImplementation.scss';
import LeadGenerationCompulsory from '../LeadGenerationForm/LeadGenerationCompulsory';
import ModalHotspot from '../../utils/custom-hooks/useModalHotspot/ModalHotspot';
import useModalHotspot from '../../utils/custom-hooks/useModalHotspot/useModalHotspot';

import { isMobileOnly } from 'react-device-detect';
import { useMobileOrientation } from '../../utils/custom-hooks/useMobileOrientation';
import { ILeadGenerationForm } from '../LeadGenerationForm/interfaces';
import { EnumLeadType } from '../LeadGenerationForm/enumerators';
import { createLeadsGeneration, ILeadsGeneration } from '../../services/leadsGenerationServices';
import Ratings from '../Ratings/Ratings';
import { createPanoramaLog, createTourLog } from '../../services/panoramaLogServices';
import {
  ICurrentPanoramaVersion,
  IPanoramaVersionsList,
  ILeadGenerationConfig,
  ILinkArguments,
  IPannellumViewer,
  IRoomsName,
  ITourInformation,
  IViewerDataFloorPlan,
  IViewerModalArgs,
  IViewerPannellumFirstPanorama,
  IViewerPannellumFloorPlan,
  IViewerPannellumPanoramas,
  IViewerPannellumPanoramaSound,
  IViewerPannellumThumbnail,
  IViewerPanoramaVersion,
  IRefObject,
  ITimes,
  IBranding,
} from './Interfaces';
import { EnumEffects, EnumPanoramaVersionStatus } from './Enumerators';
import { ReportCustomEvent } from '../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../GoogleAnalyticsTrackList';
import LoadingSpinner from '../../utils/loading-spinner/loading-spinner';
import CustomSelect from '../../utils/custom-hooks/custom-select/custom-select';
import { getFormattedDate } from '../../utils/general/general-utils';
import ModalShare from '../modal/ModalShare';

import DatePicker from '../DatePicker/DatePicker';
import { Trans, TransRenderProps } from '@lingui/react';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notification/notification';
import { useNavigate } from 'react-router-dom';
import ContactAgent from '../modal/ContactAgent';

// Viewer object
let mainViewer: IPannellumViewer;

// Viewer object
let alternativeViewer: IPannellumViewer | undefined;

// StreetView object
let streetView: google.maps.StreetViewPanorama;

// Room name
let roomsMap: Map<string, string>;

// FloorPlan with panorama id
let floorPlanWithPanorama: Map<string, string>;

interface IViewerImplementation {
  tour: IViewerPannellumPanoramas;
  firstPanorama: IViewerPannellumFirstPanorama;
  sounds?: IViewerPannellumPanoramaSound[];
  tourInformation?: ITourInformation;
  thumbnails: IViewerPannellumThumbnail[];
  floorPlanData?: IViewerDataFloorPlan[];
  floorPlan?: IViewerPannellumFloorPlan[];
  roomNames?: IRoomsName[];
  leadGeneration?: ILeadGenerationConfig;
  ratingPermission: boolean;
  ratingToken: string;
  panoramaVersions?: IPanoramaVersionsList;
  liveSharingOption?: boolean;
  liveSharingName?: string;
  liveSharingTourOwner?: string;
  brandingOptions?: IBranding;
}

let leadsShown = false;

let logInitialPanorama = true;

let logTour = true;

/**
 * ViewerImplementation component
 * @param props
 * @returns ViewerImplementation component
 * @category Component
 */
const ViewerImplementation = (props: IViewerImplementation): JSX.Element => {
  const { toggle, visible } = useModal();

  const childRef = useRef<IRefObject>(null);
  const changeWidthBarRef = useRef() as MutableRefObject<HTMLDivElement>;
  const lockBarRef = useRef() as MutableRefObject<HTMLDivElement>;
  const mainViewerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const alternativeViewerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const dateVersionPickerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const timeVersionPickerRef = useRef() as MutableRefObject<HTMLDivElement>;

  const onCancel = () => {
    toggle();
  };

  // Google map
  const [isMapEnable, setIsMapEnable] = useState(false);
  const [isStreetViewActive, setIsStreetViewActive] = useState(true);

  const [isToolbarEnable, setIsToolbarEnable] = useState(false);
  const [sceneWrapper, setSceneWrapper] = useState(false);
  const [isFloorPlanDisable, setIsFloorPlanDisable] = useState(true);
  const [isStreetViewDisable, setIsStreetViewDisable] = useState(true);
  const [isAddressBarEnable, setIsAddressBarEnable] = useState(false);
  const [isControlBarEnable, setIsControlBarEnable] = useState(false);
  const [isLeadGenerationFormEnable, setIsLeadGenerationFormEnable] = useState(false);
  const [isLeadGenerationCompulsory, setIsLeadGenerationCompulsory] = useState(false);
  const [isRatingsEnable, setIsRatingsEnable] = useState(false);
  const [isRatingToggle, setIsRatingToggle] = useState(true);
  const [currentRoomName, setCurrentRoomName] = useState('');
  const [currentRoomId, setRoomCurrentId] = useState('');

  /* ------------------------------------ Split screen ----------------------------------------- */
  const [toggleSplitScreen, setToggleSplitScreen] = useState(false);
  const [splitScreenAvailable, setSplitScreenAvailable] = useState(false);
  const [availableVersions, setAvailableVersions] = useState<ICurrentPanoramaVersion>();
  const [showCustomDropDown, setShowCustomDropDown] = useState(false);
  const [currentAlternativePanoramaUpdatedAt, setCurrentAlternativePanoramaUpdatedAt] = useState<ITimes>();
  const [currentMainPanoramaUpdatedAt, setCurrentMainPanoramaUpdatedAt] = useState<ITimes>();
  /* ------------------------------------ Split screen ----------------------------------------- */

  const { toggleHotspot, visibleHotspot } = useModalHotspot();

  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [modalURL, setModalURL] = useState('');
  const [modalType, setModalType] = useState('');
  const [enableAutoPlay, setEnableAutoPlay] = useState(false);
  const [enableControls, setEnableControls] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [linkUrl, setLinkUrl] = useState<undefined | string>(undefined);
  const [isHotspotUrl, setIsHotspotUrl] = useState(false);

  const [isLoadingLeadForm, setIsLoadingLeadForm] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  /* ------------------------------------ Notification ----------------------------------------- */
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [showModalAgent, setShowModalAgent] = useState<boolean>(false);

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    setTypeNotification(type);
    setNotificationMessage(message || 'There was an error please try again');
    setShowNotification(true);
  };

  const dollhouseViewerUrl = window.ENV.REACT_APP_3D_VIEWER_URL || process.env.REACT_APP_3D_VIEWER_URL;
  const navigate = useNavigate();

  const splitScreenStatus = useRef(true);
  const [isPuppeteerActive, setIsPuppeteerActive] = useState(true);

  /**
   * Hotspot modal handler
   * @param hotSpotDiv
   * @param modalArgs
   */
  function onHotspotModal(hotSpotDiv: HTMLElement, modalArgs: IViewerModalArgs) {
    setIsHotspotUrl(false);
    setLinkUrl(undefined);
    setModalText(modalArgs.text);
    setModalTitle(modalArgs.title);
    setModalType(modalArgs.type);

    // Text
    if (modalArgs.type === 'text') {
      if (modalArgs.url) {
        setLinkUrl(modalArgs.url);
        setIsHotspotUrl(true);
      }
    }
    // Media
    if (modalArgs.type === 'media') {
      setModalURL(modalArgs.url!);
    }

    // Video
    if (modalArgs.type === 'video') {
      setModalURL(modalArgs.url!);

      // Enable autoplay
      if (modalArgs.enableAutoplay) {
        setEnableAutoPlay(true);
      }

      // Enable video controllers
      if (modalArgs.enableControls) {
        setEnableControls(true);
      }

      // Enable video sound
      if (modalArgs.isMuted) {
        setIsMuted(true);
      }
    }

    toggleHotspot();
  }

  const onCancelHotspot = () => {
    toggleHotspot();
  };

  let tour: {};

  /**
   * Map hotspot modals methods
   * @param panoramas
   * @returns panoramas with hotspots with function assigned
   */
  const mapClickHandlerMethods = (panoramas: IViewerPannellumPanoramas) => {
    for (const id in panoramas) {
      const hotspots = panoramas[id].hotSpots;
      const hotSpotsMapped = [];
      for (const index in hotspots) {
        const hotspot = hotspots[index];

        if (hotspot.clickHandlerFunc === 'onHotspotModal') {
          hotspot.clickHandlerFunc = onHotspotModal;
        }

        if (hotspot.clickHandlerFunc === 'linkNavigation') {
          hotspot.clickHandlerFunc = sceneTransitionEffect;
        }
        hotSpotsMapped.push(hotspot);
      }
      panoramas[id].hotSpots = hotSpotsMapped;
    }
    return panoramas;
  };

  /* ---------------------------------- Link navigation -----------------------------------------------------*/

  function sceneTransitionEffect(hotSpotDiv: HTMLElement, args: ILinkArguments) {
    // Google analytics event
    ReportCustomEvent(EnumCategory.TourNavigation, EnumEvent.HotspotNavigation);

    switch (args.type) {
      case EnumEffects.zoomIn:
        zoomInEffect(args);
        break;
      case EnumEffects.fadeIn:
        loadScene(args);
        break;
      default:
        loadScene(args);
        break;
    }
  }

  /**
   * Scene walking through effect
   * @param args
   */
  function zoomInEffect(args: ILinkArguments) {
    const speed = args.durationSpeed;
    mainViewer.setPitch(args.hotspotPitch, speed);
    mainViewer.setYaw(args.hotspotYaw, speed);
    mainViewer.setHorizontalFieldOfView(50, speed, loadScene, args);
  }

  /**
   * Load scene
   * @param args
   */
  function loadScene(args: ILinkArguments) {
    mainViewer.changeScene(args.sceneId, 0, args.targetYaw);
  }

  /* ---------------------------------- Link navigation -----------------------------------------------------*/

  /* ---------------------------------- Hotspot Modal -------------------------------------------- */

  const [displayFloorPlan, setDisplayFloorPlan] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);

  const mobileOrientation = useMobileOrientation();

  const [currentFloorPlan, setCurrentFloorPlan] = useState<IViewerPannellumFloorPlan>({
    id: '',
    name: '',
    height: 0,
    width: 0,
    url: '',
    hotspots: [],
  });

  const [currentFloorPlanIndex, setCurrentFloorPlanIndex] = useState(0);

  const [maxFloorPlanIndex, setMaxFloorPlanIndex] = useState(0);

  const onShowScene = () => {
    const value = !sceneWrapper;
    setSceneWrapper(value);
  };

  const showModalAgentContact = () => {
    setShowModalAgent(!showModalAgent);
  };

  const onLinkNavigation = () => {
    const id = mainViewer.getCurrentScene();
    if (!id) {
        return;
    }

    const roomName = roomsMap.get(id);

    if (props.floorPlan && ValidationUtils.isArrayNotEmpty(props.floorPlan)) {
      const floorPlanCurrentId = floorPlanWithPanorama && floorPlanWithPanorama.get(id);

      if (floorPlanCurrentId) {
        const index = props.floorPlan.findIndex((floorPlan: IViewerPannellumFloorPlan) => floorPlan.id === floorPlanCurrentId);
        setCurrentFloorPlan(props.floorPlan[index]);
      }

      onHotspotChange(id);
    }

    setRoomCurrentId(id);
    setCurrentRoomName(roomName!);

    resetFieldOfVIewOnMobile();

    //Log First Panorama
    logPanoramaView(id);

    // Dealing with split screen
    if (props.panoramaVersions && Object.keys(props.panoramaVersions).length !== 0) {
      // Get versions for current panorama
      const currentVersions = props.panoramaVersions[id];

      // Remove split button if new panorama does not have a version
      if (!currentVersions) {
        setSplitScreenAvailable(false);
        // Close split if open and new panorama does not have other version
        if (alternativeViewer) {
          splitScreenStatus.current = false;
          setToggleSplitScreen(false);
          setTimeout(() => {
            mainViewer.resize();
          }, 200);
          alternativeViewer?.destroyViewer();
          alternativeViewer = undefined;
        }
      } else {
        updateSplitViewTimeDate(currentVersions);
        setSplitScreenAvailable(true);
        const yaw = getCurrentDeltaYaw();
        const currentAlternativeVersion = Object.keys(currentVersions).find(
          a => currentVersions[a].status === EnumPanoramaVersionStatus.Unselected,
        ) as keyof IViewerPanoramaVersion;
        if (alternativeViewer) {
          alternativeViewer?.changeScene(currentVersions[currentAlternativeVersion].id, 0, yaw);
        }
      }
    }
  };

  /**
   *
   * @param id
   */
  const onHotspotChange = (id: string) => {
    props.floorPlan!.map((floorPlan: IViewerPannellumFloorPlan) => {
      return floorPlan.hotspots.map(hotspot => {
        return hotspot.destId === id ? (hotspot.isActive = true) : (hotspot.isActive = false);
      });
    });
  };

  /**
   * On change scene
   * @param id
   */
  const OnchangeScene = (id: string, targetYaw: number) => {
    if (currentRoomId !== id) {
      setRoomCurrentId(id);
      mainViewer.changeScene(id, 0, targetYaw);
      // only does it for mobile
      resetFieldOfVIewOnMobile();
      // Close floorPlan modal on hotspot navigation for mobile
      if (isMobileOnly && typeof childRef.current === 'object' && childRef.current && displayFloorPlan) {
        childRef.current.closeFloorPlan();
      }
      setSplitScreenAvailable(false);
      // Close split screen
      if (toggleSplitScreen) {
        onSplitView();
      }
    }
  };

  /**
   * Send lead generated
   * @param leadGenerated
   * @returns
   */
  const sendLeadGenerated = async (leadGenerated: ILeadsGeneration) => {
    try {
      const response = await createLeadsGeneration(leadGenerated);
      return response;
    } catch (error) {
      //
    }
  };

  /**
   * Submit lead generated
   * @param form
   */
  const submitLeadGeneration = async (form: ILeadGenerationForm) => {
    const leadGenerated: ILeadsGeneration = {
      tourId: props.tourInformation!.id,
      leadType: props.leadGeneration?.compulsory ? EnumLeadType.Compulsory : EnumLeadType.Optional,
    };

    // Set Loading status
    setIsLoadingLeadForm(true);

    if (form.fullName) {
      leadGenerated.fullName = form.fullName;
    }

    if (form.phoneNumber) {
      leadGenerated.phoneNumber = form.phoneNumber;
    }

    if (form.email) {
      leadGenerated.email = form.email;
    }

    try {
      await sendLeadGenerated(leadGenerated);
      // Set Loading status
      setIsLoadingLeadForm(false);
      // Close modal
      toggle();
    } catch (error) {
      // console.log(error);
    }
  };

  /**
   * Get field of view according device fov{number}, fovBounds{number[]}
   * @returns
   */
  const getFieldOfView = (): [number, number[]] => {
    const landscape = window.innerWidth > window.innerHeight;
    if (isMobileOnly && !landscape) {
      return [60, [50, 60]];
    } else {
      return [120, [50, 120]];
    }
  };

  useEffect(() => {
    // componentDidMount - Initialize Viewer
    if (
      props.tour &&
      props.thumbnails &&
      props.firstPanorama &&
      props.tourInformation &&
      props.roomNames &&
      props.leadGeneration &&
      props.floorPlanData &&
      props.panoramaVersions
    ) {
      // Room names
      roomsMap = new Map<string, string>(props.roomNames);

      if (props.panoramaVersions && Object.keys(props.panoramaVersions).length !== 0) {
        // Get versions for first panorama
        if (Object.keys(props.panoramaVersions).length !== 0) {
          const currentVersions = props.panoramaVersions[props.firstPanorama.id];
          if (currentVersions) {
            setSplitScreenAvailable(true);
            const params = new URLSearchParams(window.location.search);
            const splitView = params.get('splitView');
            if (splitView) {
              setToggleSplitScreen(true);
              onSplitView();
            }
          } else {
            setSplitScreenAvailable(false);
          }
        }
      }

      // Floorplan with panoramas
      if (props.floorPlanData && ValidationUtils.isArrayNotEmpty(props.floorPlanData)) {
        floorPlanWithPanorama = new Map<string, string>(props.floorPlanData);
      }

      const roomName = roomsMap.get(props.firstPanorama.id);
      setCurrentRoomName(roomName!);
      setRoomCurrentId(props.firstPanorama.id);

      leadsShown = true;
      // Initialize viewer
      // eslint-disable-next-line
      tour = mapClickHandlerMethods(props.tour);
      initMainViewer();
    }

    // componentWillUnmount - Destroy Viewer
    return () => {
      mainViewer && mainViewer.destroyViewer();

      streetView && streetView.setVisible(false);
    };
  }, [
    props.tour,
    props.thumbnails,
    props.firstPanorama,
    props.tourInformation,
    props.roomNames,
    props.leadGeneration,
    props.floorPlanData,
    props.panoramaVersions,
  ]);

  /**
   * Init Viewer
   */
  const initMainViewer = () => {
    // code to run on component mount
    const type = props.tour[props.firstPanorama.id].type;

    let config: IViewerConfig = {
      type: type,
      default: {
        firstScene: props.firstPanorama.id,
        sceneFadeDuration: 1000,
      },
      // Max zoom out
      //   hfov: fov,
      scenes: tour,
      crossOrigin: 'anonymous',
      doubleClickZoom: false,
      // yaw: props.firstPanorama.yaw,
      showControls: false,
      autoLoad: true,
      // TODO - review
      autoRotate: props.tourInformation!.isAutoRotateEnable ? -6 : 0,
      // delay, in milliseconds, to start automatically rotating the panorama after user activity ceases.
      // autoRotateInactivityDelay: 2000
    };

    let multiResEnable = false;

    if (type === 'multires') {
      multiResEnable = true;
    }

    mainViewer = new Viewer('main-viewer', config);

    mainViewer.setOnLoad(displayComponents);
    mainViewer.setOnScenechange(onLinkNavigation);

    if (isMobileOnly) {
      mainViewer.startOrientation();
      resetFieldOfVIewOnMobile();
    }

    if (multiResEnable) {
      displayComponents();
    }
  };

  // --------------------------------------------------------------------- SPLIT VIEW -----------------------------------------------------------------------------------

  const onChangeDate = (timestamp: string | number) => {
    const currentDate = new Date(timestamp);
    const object = { ...currentAlternativePanoramaUpdatedAt };
    object.date = currentDate || new Date();
    setCurrentAlternativePanoramaUpdatedAt({
      id: currentAlternativePanoramaUpdatedAt?.id || '',
      updatedAt: currentAlternativePanoramaUpdatedAt?.updatedAt || '',
      date: currentDate,
    });
  };

  const initAlternativeViewer = () => {
    tour = mapClickHandlerMethods(props.tour);

    const currentId = mainViewer.getCurrentScene() as keyof IViewerPanoramaVersion;

    if (props.panoramaVersions && Object.keys(props.panoramaVersions).length !== 0) {
      const currentVersions = props.panoramaVersions[currentId];

      // Get first alternative version with status unselected status
      const currentAlternativeVersion = Object.keys(currentVersions).find(
        a => currentVersions[a].status === EnumPanoramaVersionStatus.Unselected,
      ) as keyof IViewerPanoramaVersion;

      if (currentVersions) {
        updateSplitViewTimeDate(currentVersions);

        // code to run on component mount
        const config: IViewerConfig = {
          type: currentVersions[currentAlternativeVersion].type,
          hfov: currentVersions[currentAlternativeVersion].hfov,
          scenes: tour,
          crossOrigin: 'anonymous',
          doubleClickZoom: false,
          showControls: false,
          autoLoad: true,
        };

        if (currentVersions[currentAlternativeVersion].type === 'cubemap') {
          config.cubeMap = currentVersions[currentAlternativeVersion].cubeMap;
        } else {
          config.multiRes = currentVersions[currentAlternativeVersion].multiRes;
        }

        alternativeViewer = new Viewer('alternative-viewer', config);
      }
    }
  };

  const updateSplitViewTimeDate = (currentVersions: ICurrentPanoramaVersion) => {
    const currentId = mainViewer.getCurrentScene() as keyof IViewerPanoramaVersion;

    // Get first alternative version with status unselected status
    const currentAlternativeVersion = Object.keys(currentVersions).find(
      a => currentVersions[a].status === EnumPanoramaVersionStatus.Unselected,
    ) as keyof IViewerPanoramaVersion;

    // Get main panorama creation date status selected
    const currentMainVersion = Object.keys(currentVersions).find(
      a => currentVersions[a].status === EnumPanoramaVersionStatus.Selected,
    ) as keyof IViewerPanoramaVersion;

    if (currentMainVersion) {
      const formattedDateMain = getFormattedDate(currentVersions[currentMainVersion].updatedAt);

      setCurrentMainPanoramaUpdatedAt({
        id: currentVersions[currentMainVersion].id,
        updatedAt: formattedDateMain,
        date: currentVersions[currentMainVersion].updatedAt,
      });
    }

    if (currentAlternativeVersion) {
      const formattedDate = getFormattedDate(currentVersions[currentAlternativeVersion].updatedAt);
      setCurrentAlternativePanoramaUpdatedAt({
        id: currentVersions[currentAlternativeVersion].id,
        updatedAt: formattedDate,
        date: currentVersions[currentAlternativeVersion].updatedAt,
      });
    }

    delete currentVersions[currentId];
    setAvailableVersions(currentVersions);
  };

  const getCurrentDeltaYaw = () => {
    const currentId = mainViewer.getCurrentScene() as keyof IViewerPanoramaVersion;

    let currentVersion: IViewerPanoramaVersion;

    let newDeltaYaw = 180;

    if (props.panoramaVersions && Object.keys(props.panoramaVersions).length !== 0) {
      // Get versions for current panorama
      const currentVersions = props.panoramaVersions[currentId];

      if (currentVersions) {
        currentVersion = Object.values(currentVersions)[0];
        newDeltaYaw = currentVersion.yaw - props.tour[currentId].yaw;
      }
    }

    return newDeltaYaw;
  };

  const setWidth = () => {
    setToggleSplitScreen(!toggleSplitScreen);

    if (toggleSplitScreen) {
      mainViewerRef.current.style.width = '100%';
      alternativeViewerRef.current.style.visibility = 'hidden';
    } else {
      mainViewerRef.current.style.width = '50%';
      alternativeViewerRef.current.style.visibility = 'visible';
      alternativeViewerRef.current.style.left = '50%';
      alternativeViewerRef.current.style.width = '50%';
    }

    setTimeout(() => {
      mainViewer.resize();
    }, 200);
  };

  const openModalShare = () => {
    if (isMobileOnly) {
      navigate('/create-live-meeting', { state: { tourName: props.liveSharingName, tourOwner: props.liveSharingTourOwner } });
    } else {
      setDisplayModal(!displayModal);
    }
  };

  const onSplitView = () => {
    setWidth();
    if (alternativeViewer && alternativeViewer.getViewer()) {
      setTimeout(() => {
        splitScreenStatus.current = true;
        alternativeViewer?.destroyViewer();
        alternativeViewer = undefined;
      }, 200);
    } else {
      setTimeout(() => {
        initAlternativeViewer();
        setPuppeteer();
      }, 200);
    }
  };

  const setPuppeteer = () => {
    mainViewer.setOnMousedown(createGetCoordinatesEventListener, true);
    mainViewer.setOnMouseup(deleteGetCoordinatesEventListener);

    if (alternativeViewer) {
      alternativeViewer.setOnMousedown(alternativeCreateGetCoordinatesEventListener, true);
      alternativeViewer.setOnMouseup(alternativeDeleteGetCoordinatesEventListener);
    }
  };

  const createGetCoordinatesEventListener = () => {
    document.addEventListener('mousemove', syncAlternativeViewer);
  };

  const deleteGetCoordinatesEventListener = () => {
    document.removeEventListener('mousemove', syncAlternativeViewer);
  };

  const alternativeCreateGetCoordinatesEventListener = () => {
    document.addEventListener('mousemove', syncMainViewer);
  };

  const alternativeDeleteGetCoordinatesEventListener = () => {
    document.removeEventListener('mousemove', syncMainViewer);
  };

  function syncAlternativeViewer() {
    if (splitScreenStatus.current) {
      const deltaYaw = getCurrentDeltaYaw();
      if (alternativeViewer) {
        if (mainViewer.getHorizontalFieldOfView() !== alternativeViewer.getHorizontalFieldOfView()) {
          alternativeViewer.setHorizontalFieldOfView(mainViewer.getHorizontalFieldOfView(), false);
        }
        alternativeViewer.setPitch(mainViewer.getPitch(), false);
        // yaw difference
        alternativeViewer.setYaw(mainViewer.getYaw() + deltaYaw, false);
      }
    }
  }

  function syncMainViewer() {
    if (splitScreenStatus.current) {
      const deltaYaw = getCurrentDeltaYaw();
      if (alternativeViewer) {
        if (alternativeViewer.getHorizontalFieldOfView() !== mainViewer.getHorizontalFieldOfView()) {
          mainViewer.setHorizontalFieldOfView(alternativeViewer.getHorizontalFieldOfView(), false);
        }

        mainViewer.setPitch(alternativeViewer.getPitch(), false);
        // yaw difference
        mainViewer.setYaw(alternativeViewer.getYaw() - deltaYaw, false);
      }
    }
  }

  const onPuppeteerChange = () => {
    document.removeEventListener('mousemove', syncAlternativeViewer);
    document.removeEventListener('mousemove', syncMainViewer);
  };

  const changeDropdownValue = (id: string) => {
    toggleCustomDropdown();
    const currentId = mainViewer.getCurrentScene();

    if (props.panoramaVersions) {
      const currentVersion = props.panoramaVersions[currentId][id];
      if (currentVersion?.updatedAt) {
        const formattedDate = getFormattedDate(currentVersion.updatedAt);
        const object = {
          id: currentVersion.id,
          updatedAt: formattedDate,
          date: currentAlternativePanoramaUpdatedAt?.date || new Date(),
        };
        setCurrentAlternativePanoramaUpdatedAt(object);
      }
    }

    if (alternativeViewer) {
      const yaw = getCurrentDeltaYaw();
      alternativeViewer.changeScene(id, 0, yaw);
    }
  };

  const toggleCustomDropdown = (option?: boolean) => {
    if (option) {
      setShowCustomDropDown(option);
    } else {
      setShowCustomDropDown(!showCustomDropDown);
    }
  };

  const startResize = (e: React.MouseEvent) => {
    e.preventDefault();

    let prevX = e.clientX;

    const mouseMoveFunc = (e: MouseEvent): void => {
      e.preventDefault();

      const newX = prevX - e.clientX;

      const rect = changeWidthBarRef.current.getBoundingClientRect();

      const newPost = rect.left - newX;

      mainViewerRef.current.style.width = newPost + 'px';

      alternativeViewerRef.current.style.width = window.innerWidth - newPost + 'px';

      alternativeViewerRef.current.style.left = newPost + 'px';

      changeWidthBarRef.current.style.left = newPost + 'px';

      lockBarRef.current.style.left = newPost + 'px';

      dateVersionPickerRef.current.style.left = newPost + 50 + 'px';

      timeVersionPickerRef.current.style.left = newPost + 50 + 'px';

      prevX = e.clientX;
    };

    const mouseUpFunc = (e: MouseEvent): void => {
      e.preventDefault();

      window.removeEventListener('mousemove', mouseMoveFunc);
      window.removeEventListener('mouseup', mouseUpFunc);
    };

    window.addEventListener('mousemove', mouseMoveFunc);
    window.addEventListener('mouseup', mouseUpFunc);
  };

  // --------------------------------------------------------------------- SPLIT VIEW -----------------------------------------------------------------------------------

  // --------------------------------------------------------------------- GENERAL CONFIG -----------------------------------------------------------------------------------

  /**
   * Change field of view when mobile and portrait
   */
  useEffect(() => {
    resetFieldOfVIewOnMobile();
    // eslint-disable-next-line
  }, [mobileOrientation.isPortrait]);

  const resetFieldOfVIewOnMobile = () => {
    if (mainViewer && isMobileOnly) {
      const [fov, fovBounds]: [number, number[]] = getFieldOfView();
      mainViewer.setHorizontalFieldOfViewBounds(fovBounds);
      mainViewer.setHorizontalFieldOfView(fov);
    }
  };

  useEffect(() => {
    // Set current floor plan on when props first load
    if (props.floorPlan && ValidationUtils.isArrayNotEmpty(props.floorPlan)) {
      setIsFloorPlanDisable(false);
      const floorPlanCurrentId = floorPlanWithPanorama && floorPlanWithPanorama.get(props.firstPanorama.id);

      if (floorPlanCurrentId) {
        const index = props.floorPlan.findIndex(floorPlan => floorPlan.id === floorPlanCurrentId);
        setCurrentFloorPlan(props.floorPlan[index]);
        setCurrentFloorPlanIndex(index);
      } else {
        // If the initial room do not have a corresponding floor plan display the first one
        setCurrentFloorPlan(props.floorPlan[0]);
        setCurrentFloorPlanIndex(0);
      }

      setMaxFloorPlanIndex(props.floorPlan.length - 1);
    }
  }, [props]);

  /**
   * Display components
   */
  const displayComponents = () => {
    setIsMapEnable(false);
    setIsToolbarEnable(true);
    setIsAddressBarEnable(true);
    setIsControlBarEnable(true);
    setIsLoading(false);

    // rating permission
    if (props.ratingPermission) {
      setIsRatingsEnable(true);
      setSceneWrapper(true);
    }

    if (ValidationUtils.isGeoCoordinatesValid(props.tourInformation!.latitude, props.tourInformation!.longitude)) {
      setIsStreetViewDisable(false);
    }

    if (props.leadGeneration?.status && leadsShown) {
      leadsShown = false;
      if (props.leadGeneration.compulsory) {
        // show compulsory leads
        setIsLeadGenerationCompulsory(true);
        toggle();
      } else {
        setIsLeadGenerationFormEnable(true);
        toggle();
      }
    }
    //Log Initial Panorama
    if (logInitialPanorama) {
      logPanoramaView(props.firstPanorama.id);
      logInitialPanorama = false;
    }

    if (logTour) {
      logTourView();
    }
  };

  /**
   * Move between floor plans
   * @param move
   */
  const slideFloorPlan = (move: number) => {
    if (move > 0 && currentFloorPlanIndex !== maxFloorPlanIndex) {
      setCurrentFloorPlanIndex(currentFloorPlanIndex + move);
      setCurrentFloorPlan(props.floorPlan![currentFloorPlanIndex + move]);
    }

    if (move < 0 && currentFloorPlanIndex !== 0) {
      setCurrentFloorPlanIndex(currentFloorPlanIndex + move);
      setCurrentFloorPlan(props.floorPlan![currentFloorPlanIndex + move]);
    }
  };

  const zoomIn = () => {
    mainViewer.zoomIn(25);
  };

  const zoomOut = () => {
    mainViewer.zoomOut(25);
  };
  const fullScreen = () => {
    mainViewer.fullScreen();
  };

  // --------------------------------------------------------------------- GENERAL CONFIG -----------------------------------------------------------------------------------

  /** ------------------------------------------------------- GOOGLE STREET VIEW ------------------------------------------------------- */

  const setViewer = () => {
    setIsMapEnable(!isMapEnable);
    setIsAddressBarEnable(!isAddressBarEnable);
    setIsControlBarEnable(!isControlBarEnable);
    setIsLeadGenerationFormEnable(!isLeadGenerationFormEnable);

    if (props.ratingPermission) {
      setIsRatingsEnable(!isRatingsEnable);
    }

    onMobileStreetView();
  };

  const startStreet = () => {
    setIsMapEnable(!isMapEnable);
    setIsAddressBarEnable(!isAddressBarEnable);
    setIsControlBarEnable(!isControlBarEnable);
    setIsLeadGenerationFormEnable(!isLeadGenerationFormEnable);

    if (props.ratingPermission) {
      setIsRatingsEnable(!isRatingsEnable);
    }

    onMobileStreetView();
  };

  const switchMap = () => {
    setIsStreetViewActive(!isStreetViewActive);
  };

  /** ------------------------------------------------------- GOOGLE STREET VIEW ------------------------------------------------------- */

  const onDisplayFloorPlan = () => {
    setDisplayFloorPlan(!displayFloorPlan);
  };

  /** ------------------------------------------------------- MOBILE ------------------------------------------------------- */

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isMobileStreetView, setIsMobileStreetView] = useState(false);

  const onMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const onMobileStreetView = () => {
    setIsMobileStreetView(!isMobileStreetView);
  };

  /** ------------------------------------------------------- MOBILE ------------------------------------------------------- */

  /** ------------------------------------------------------- LOGS ------------------------------------------------------- */
  const logPanoramaView = async (panoramaId: string) => {
    const refererUrl = document.referrer ? document.referrer : 'localhost';
    await createPanoramaLog(props.tourInformation!.userId, props.tourInformation!.id, panoramaId, refererUrl);
  };

  const logTourView = async () => {
    const refererUrl = document.referrer ? document.referrer : 'localhost';
    await createTourLog(props.tourInformation!.userId, props.tourInformation!.id, refererUrl);
    logTour = false;
  };

  /** ------------------------------------------------------- LOGS ------------------------------------------------------- */

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner />}
      {!isLoading && isAddressBarEnable && isControlBarEnable && (
        <div className='header-container'>
          {isAddressBarEnable ? <AddressBar tourAddress={props.tourInformation!.name} roomName={currentRoomName} /> : null}
          {isControlBarEnable ? (
            <ControlsBar
              isRatingToggle={isRatingToggle}
              currentRoomId={currentRoomId}
              onZoomIn={zoomIn}
              onZoomOut={zoomOut}
              onFullScreen={fullScreen}
              setIsRatingToggle={setIsRatingToggle}
              isRatingsEnable={isRatingsEnable}
              sounds={props.sounds ? props.sounds : null}
            />
          ) : null}
        </div>
      )}
      <div className='viewer-container'>
        {showNotification && !isLoading && (
          <Notification
            showNotification={showNotification}
            size={EnumNotificationSize.Large}
            type={typeNotification || EnumNotificationType.Info}
            message={notificationMessage}
            setShowNotification={setShowNotification}
          />
        )}
        {/* -------------------  Viewer --------------------------------- */}
        <div id='main-viewer' ref={mainViewerRef} className={`viewer-left-general-class ${toggleSplitScreen ? 'left-viewer' : 'full'}`} />
        {toggleSplitScreen && (
          <div ref={changeWidthBarRef} onMouseDown={(e: React.MouseEvent) => startResize(e)} className='viewer-resizer'></div>
        )}

        {!toggleSplitScreen && !isLoading && dollhouseViewerUrl.includes('dev') && props.liveSharingOption && (
          <div className={!isMobileOnly ? 'live-share-btn' : 'live-share-btn-mobile'} onClick={() => openModalShare()}>
            <Trans id='SHARE-LIVE' />
          </div>
        )}

        {props.brandingOptions && (
          <div>
            <div
              className={showModalAgent ? 'contact-agent-open' : 'contact-agent'}
              style={{ right: showModalAgent ? '279px' : '0' }}
              onClick={showModalAgentContact}
            />
            <ContactAgent showModalAgent={showModalAgent} brandingOptions={props.brandingOptions} />
          </div>
        )}

        {displayModal && !isMobileOnly && (
          <>
            <ModalShare
              toggleNotification={toggleNotification}
              openModalShare={openModalShare}
              tourName={props?.liveSharingName || ''}
              tourOwner={props?.liveSharingTourOwner || ''}
            />
            {!isMobileOnly && <div className='modal-share-overlay'></div>}
          </>
        )}

        <div
          id='alternative-viewer'
          ref={alternativeViewerRef}
          className={`viewer-right-general-class ${toggleSplitScreen ? 'right-viewer' : ''}`}
        />
        {toggleSplitScreen ? (
          <>
            <button onClick={() => onSplitView()} className='btn-close-split-screen'>
              <i style={{ fontSize: '18px' }} className='fal fa-times'></i>
            </button>
            <CustomSelect
              value={currentAlternativePanoramaUpdatedAt}
              options={availableVersions}
              changeDropdownValue={changeDropdownValue}
              showCustomDropDown={showCustomDropDown}
              setShowCustomDropDown={toggleCustomDropdown}
              top={'130px'}
              left={'54%'}
              currentAlternativeDate={currentAlternativePanoramaUpdatedAt?.date}
              versionPickerRef={timeVersionPickerRef}
            />
            <CustomSelect value={currentMainPanoramaUpdatedAt} showCustomDropDown={false} top={'130px'} left={'30px'} />

            <div
              ref={lockBarRef}
              id='btn-puppeteer'
              className={`btn-puppeteer ${splitScreenStatus.current ? 'active-puppeteer' : 'inactive-puppeteer'}`}
              style={{ bottom: sceneWrapper ? '20%' : '2%' }}
              onClick={() => {
                onPuppeteerChange();
                splitScreenStatus.current = !splitScreenStatus.current;
                setIsPuppeteerActive(!isPuppeteerActive);
              }}
            >
              <i className={splitScreenStatus.current ? 'fal fa-lock-alt' : 'fal fa-lock-open-alt'}></i>
            </div>
            <DatePicker
              id='main-viewer-date-picker'
              onChange={onChangeDate}
              customDate={currentMainPanoramaUpdatedAt?.date}
              top={'80px'}
              left={'30px'}
              leftCalendar={'120%'}
            />
            <DatePicker
              id='alternative-viewer-date-picker'
              onChange={onChangeDate}
              customDate={currentAlternativePanoramaUpdatedAt?.date}
              top={'80px'}
              left={'54%'}
              leftCalendar={'120%'}
              availableVersions={availableVersions}
              versionPickerRef={dateVersionPickerRef}
            />
          </>
        ) : null}

        {/* -------------------  Viewer --------------------------------- */}
        {isRatingsEnable ? (
          <div className={isRatingToggle ? 'show-rating' : 'hide-rating'}>
            <Ratings tourId={props.tourInformation!.id} currentRoomId={currentRoomId} ratingToken={props.ratingToken} />
          </div>
        ) : null}
        {isMapEnable ? (
          <React.Fragment>
            <div className='virtualTourBtn' onClick={() => startStreet()}>
              <i className='virtualTourBtn-icon' />
              <p>
                <Trans id='360° View' />
              </p>
            </div>
            {!isStreetViewActive ? (
              <div className='mapBtn' onClick={() => switchMap()}>
                <i className='mapBtn-icon' />
                <p>
                  <Trans id='Map View' />
                </p>
              </div>
            ) : (
              <div className='streetViewBtn' onClick={() => switchMap()}>
                <i className='streetViewBtn-icon' />
                <p>
                  <Trans id='Street View' />
                </p>
              </div>
            )}
            <GoogleMap
              isStreetViewActive={isStreetViewActive}
              latitude={props.tourInformation!.latitude}
              longitude={props.tourInformation!.longitude}
              zoom={18}
            />
          </React.Fragment>
        ) : null}
        {/* -------------------  Floor Plan --------------------------------- */}
        {!isFloorPlanDisable ? (
          <FloorPlan
            currentFloorPlanIndex={currentFloorPlanIndex}
            floorPlans={props.floorPlan || []}
            currentFloorPlan={currentFloorPlan}
            onChangeScene={OnchangeScene}
            displayFloorPlan={displayFloorPlan}
            slideFloorPlan={slideFloorPlan}
          />
        ) : null}
        {/* -------------------  Floor Plan --------------------------------- */}
        {isLeadGenerationFormEnable && (
          <Trans
            id='Your information won`t be shared with anyone else.'
            render={({ translation }: TransRenderProps) => (
              <Modal
                compulsory={false}
                visible={visible}
                title={props.leadGeneration?.message}
                text={translation as string}
                childComp={
                  <LeadGenerationForm
                    config={props.leadGeneration}
                    isLoading={isLoadingLeadForm}
                    submitLeadGeneration={submitLeadGeneration}
                  />
                }
                onCancel={onCancel}
                enableButtonGroup={false}
              />
            )}
          />
        )}

        {isLeadGenerationCompulsory && (
          <Trans
            id='Your information won`t be shared with anyone else.'
            render={({ translation }: TransRenderProps) => (
              <Modal
                compulsory={true}
                visible={visible}
                title={props.leadGeneration?.message}
                text={translation as string}
                childComp={
                  <LeadGenerationCompulsory
                    config={props.leadGeneration}
                    isLoading={isLoadingLeadForm}
                    submitLeadGeneration={submitLeadGeneration}
                  />
                }
                onCancel={onCancel}
                enableButtonGroup={false}
              />
            )}
          />
        )}
        <ModalHotspot
          visible={visibleHotspot}
          isHotspotUrl={isHotspotUrl}
          tittle={modalTitle}
          text={modalText}
          url={linkUrl}
          childComp={
            modalType === 'media' || modalType === 'video' ? (
              modalType === 'media' ? (
                <img className='img-hotspot-modal' src={modalURL} alt='modal' />
              ) : (
                <video className='video-hotspot-modal' muted={isMuted} autoPlay={enableAutoPlay} controls={enableControls} src={modalURL} />
              )
            ) : null
          }
          onCancel={onCancelHotspot}
          modalType={modalType}
          enableButtonGroup={false}
        />
        {isToolbarEnable ? (
          <Toolbar
            ref={childRef}
            onShowScene={onShowScene}
            onDisplay={sceneWrapper}
            onChangeScene={OnchangeScene}
            thumbNailsList={props.thumbnails}
            onStartStreetView={startStreet}
            onStartViewer={setViewer}
            currentRoomId={currentRoomId}
            onDisplayFloorPlan={onDisplayFloorPlan}
            isFloorPlan={isFloorPlanDisable}
            isStreetView={isStreetViewDisable}
            showMobileMenu={showMobileMenu}
            onMobileMenu={onMobileMenu}
            isMobileStreetView={isMobileStreetView}
            sounds={props.sounds ? props.sounds : undefined}
            splitScreenAvailable={splitScreenAvailable}
            toggleSplitScreen={toggleSplitScreen}
            onSplitView={onSplitView}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

export { ViewerImplementation };
export type { IViewerImplementation };
