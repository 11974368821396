import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { getDateNoTime } from '../../utils/general/general-utils';
import { ICurrentPanoramaVersion } from '../Viewer/Interfaces';
import { daysMap, monthMap, todayTimestamp } from './Constants';
import './DatePicker.scss';
import { DatePickerDesign, IDayDetails, IDay } from './Interfaces';

interface IDatePicker {
  onChange: (timestamp: string | number) => void;
  customDate?: Date;
  id?: string;
  // Style
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  leftCalendar?: string;
  rightCalendar?: string;
  availableVersions?: ICurrentPanoramaVersion;
  versionPickerRef?: MutableRefObject<HTMLDivElement>;
}

/**
 * DatePicker component
 * @param props
 * @returns DatePicker component
 * @category Component
 */
const DatePicker = (props: IDatePicker): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  let date = props.customDate ? new Date(props.customDate) : new Date();
  let dateSelected = todayTimestamp; // props.customDate ? Date.parse(props.customDate) : todayTimestamp;

  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());
  const [selectedDay, setSelectedDay] = useState(dateSelected);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [panoramaVersions, setPanoramaVersions] = useState<ICurrentPanoramaVersion>();

  useEffect(() => {
    const onClick = (event: Event & { target: HTMLInputElement }): void => {
      event.preventDefault();
      // If the active element exists and is clicked outside of
      const wrapper = props.versionPickerRef ? props.versionPickerRef : wrapperRef;
      if (wrapper.current !== null && !wrapper.current.contains(event.target)) {
        if (showDatePicker) {
          setShowDatePicker(false);
        }
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (showDatePicker) {
      window.addEventListener('click', onClick as EventListener);
    }

    return () => {
      window.removeEventListener('click', onClick as EventListener);
    };
    // eslint-disable-next-line
  }, [showDatePicker, wrapperRef, props.versionPickerRef]);

  useEffect(() => {
    if (props.customDate) {
      const formatedDate = getDateStringFromTimestamp(props.customDate);
      setCurrentDate(formatedDate);
    }
  }, [props.customDate]);

  useEffect(() => {
    if (props.availableVersions) {
      setPanoramaVersions(props.availableVersions);
    }
  }, [props.availableVersions]);

  const getNumberOfDays = (year: number, month: number) => {
    return 40 - new Date(year, month, 40).getDate();
  };

  const getDayDetails = (args: IDayDetails) => {
    let date = args.index - args.firstDay;
    let day = args.index % 7;
    let prevMonth = args.month - 1;
    let prevYear = args.year;
    if (prevMonth < 0) {
      prevMonth = 11;
      prevYear--;
    }
    let prevMonthNumberOfDays = getNumberOfDays(prevYear, prevMonth);
    let _date = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
    let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
    let timestamp = new Date(args.year, args.month, _date).getTime();
    return {
      date: _date,
      day,
      month,
      timestamp,
      dayString: daysMap[day],
    };
  };

  // eslint-disable-next-line
  const getDayDetailsForDate = (dateString: string) => {
    let date = new Date(dateString);
    let dateMonth = date.getMonth();
    let dateTimeStamp = date.getTime();
    let dateDay = date.getDay();

    return {
      date: Date.parse(dateString),
      day: dateDay,
      month: dateMonth,
      timestamp: dateTimeStamp,
      dayString: daysMap[dateDay],
    };
  };

  const getMonthDetails = (year: number, month: number) => {
    let firstDay = new Date(year, month).getDay();
    let numberOfDays = getNumberOfDays(year, month);
    let monthArray = [];
    let rows = 5;
    let currentDay = null;
    let index = 0;
    let cols = 7;

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        currentDay = getDayDetails({
          index,
          numberOfDays,
          firstDay,
          year,
          month,
        });

        monthArray.push(currentDay);
        index++;
      }
    }
    return monthArray;
  };

  const [monthDetails, setMonthDetails] = useState(getMonthDetails(year, month));

  const style: DatePickerDesign = {
    top: props.top,
    left: props.left,
    bottom: props.bottom,
    right: props.right,
  };

  const calendarStyle: DatePickerDesign = {
    right: props.rightCalendar,
    left: props.leftCalendar,
  };

  const handleShowDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const isSelectedDay = (day: IDay) => {
    return day.timestamp === selectedDay;
  };

  const getMonthStr = (month: number) => {
    return monthMap[month] ?? 'Month';
  };

  const getDateStringFromTimestamp = (timestamp: number | Date) => {
    const dateObject = new Date(timestamp);
    const month = monthMap[dateObject.getMonth()];
    const date = dateObject.getDate();

    return date + ' ' + month + ' ' + dateObject.getFullYear();
  };

  const setDateToInput = (timestamp: number) => {
    let dateString = getDateStringFromTimestamp(timestamp);
    setCurrentDate(dateString);
  };

  const onDateClick = (day: IDay) => {
    setSelectedDay(day.timestamp);
    setDateToInput(day.timestamp);
    if (props.onChange) {
      props.onChange(day.timestamp);
    }
  };

  const setMonthHelper = (offset: number) => {
    let auxYear = year;
    let auxMonth = month + offset;
    if (auxMonth === -1) {
      auxMonth = 11;
      auxYear--;
    } else if (auxMonth === 12) {
      auxMonth = 0;
      auxYear++;
    }

    setYear(auxYear);
    setMonth(auxMonth);
    setMonthDetails(getMonthDetails(auxYear, auxMonth));
  };

  const renderCalendar = () => {
    let days = monthDetails.map((day: IDay, index: number) => {
      const date = getDateNoTime(new Date(day.timestamp));
      let checkAvailableDates = false;
      if (panoramaVersions) {
        checkAvailableDates = Object.keys(panoramaVersions).some(version => {
          const availableDates = getDateNoTime(panoramaVersions[version].updatedAt);
          return availableDates === date;
        });
      }

      return (
        <div
          className={'c-day-container ' + (!checkAvailableDates ? ' disabled' : '') + (isSelectedDay(day) ? ' highlight-green' : '')}
          key={index}
        >
          <div className='cdc-day'>
            <span onClick={() => onDateClick(day)}>{day.date}</span>
          </div>
        </div>
      );
    });

    return (
      <div className='c-container'>
        <div className='cc-head'>
          {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((d, i) => (
            <div key={i} className='cch-name'>
              {d}
            </div>
          ))}
        </div>
        <div className='cc-body'>{days}</div>
      </div>
    );
  };

  return (
    <div ref={props.versionPickerRef ? props.versionPickerRef : wrapperRef} className='MyDatePicker' style={style}>
      <div
        className={`header-date-container ${showDatePicker ? 'date-picker-active' : 'date-picker-inactive'}`}
        onClick={() => {
          if (props.availableVersions) {
            handleShowDatePicker();
          }
        }}
      >
        <div>
          <i className='fal fa-calendar-alt calendar-icon'></i>
        </div>
        <div>{currentDate}</div>
      </div>
      {props.availableVersions && showDatePicker && (
        <div className='mdp-container' style={calendarStyle}>
          <div className='mdpc-head'>
            <div className='mdpch-button'>
              <div className='mdpchb-inner' onClick={() => setMonthHelper(-1)}>
                <i className='far fa-chevron-left'></i>
              </div>
            </div>
            <div className='mdpch-container'>
              <div className='mdpchc-month'>{getMonthStr(month)}</div>
              <div className='mdpchc-year'>{year}</div>
            </div>
            <div className='mdpch-button'>
              <div className='mdpchb-inner' onClick={() => setMonthHelper(1)}>
                <i className='far fa-chevron-right'></i>
              </div>
            </div>
          </div>
          <div className='mdpc-body'>{renderCalendar()}</div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
