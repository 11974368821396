import { Trans } from '@lingui/react';
import React from 'react';
import './WrongPosition.scss';
import portrait from '../../images/modal/portrait.svg';
const WrongPosition = () => {
  return (
    <div className='wrong-position-container'>
      <div className='text-container'>
        <div className='title-position'>
          <Trans id='Turn your phone in portrait mode' />
        </div>
        <div className='subtext-position'>
          <Trans id='Rotate your mobile in portrait mode to have the best experience' />
        </div>
      </div>
      <div className='image-wrong-turn-container'>
        <img className='' src={portrait} alt='icon' />
      </div>
    </div>
  );
};

export default WrongPosition;
