import React from 'react';
import './AddressBar.scss';

/**
 * This is the description of the interface
 *
 * @interface Props
 * @roomName {string} is used for display the current room name
 * @tourAddress {string} is used for displaying the property address
 */
interface IAddressBarProps {
  roomName: string;
  tourAddress?: string;
}

/**
 * Address bar component
 * @param props
 * @returns AddressBar component
 * @category Component
 */
const AddressBar = (props: IAddressBarProps): JSX.Element => {
  return (
    <div className='address-bar'>
      <p className='address-bar-text'>
        <span>{props.roomName}</span>
      </p>
    </div>
  );
};

export { AddressBar };
export type { IAddressBarProps };
