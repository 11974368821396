import React, { useState, useEffect, useCallback } from 'react';
import './sound-player.scss';
import { i18n } from '@lingui/core';
import { isMobile } from 'react-device-detect';

interface Props {
  url: string;
  audioLoop?: boolean;
  audioPlay?: boolean;
  slider: boolean;
}

const useAudio = (url: string, audioLoop?: boolean, audioPlay?: boolean): [boolean, () => void] => {
  const [audio, setAudio] = useState<HTMLAudioElement>();
  const [playing, setPlaying] = useState<boolean>(audioPlay ? !audioPlay : false);
  const [loop] = useState(audioLoop ? audioLoop : false);
  const toggle = useCallback((): void => setPlaying(state => !state), []);

  useEffect(() => {
    if (url) {
      setAudio(new Audio(url));
    }
    // eslint-disable-next-line
  }, [url]);

  const autoPlayEvent = () => {
    if (audioPlay) {
      setPlaying(true);
      document.removeEventListener('click', autoPlayEvent);
    }
  };

  useEffect(() => {
    document.addEventListener('click', autoPlayEvent);
    // eslint-disable-next-line
  }, [url]);

  useEffect(() => {
    if (audio && isMobile) {
      audio.loop = loop;
      playing ? audio.play() : audio.pause();
    }

    // Stops audio when panorama is changed
    return () => {
      if (audio) {
        audio.pause();
      }
    };
    // eslint-disable-next-line
  }, [playing, audio]);

  return [playing, toggle];
};

const PlayerMobile = ({ url, audioLoop, audioPlay, slider }: Props) => {
  const [playing, setPlaying] = useAudio(url, audioLoop, audioPlay);

  return (
    <div id='sound'>
      <div onClick={setPlaying}>
        <div
          title={String(i18n.messages.control_bar_sounds)}
          className={`mobile-sound-btn  ${
            slider
              ? 'mobile-audio-landscape'
              : playing
              ? 'mobile-audio-img-active mobile-audio-icon-position-close'
              : 'mobile-audio-img-pressed mobile-audio-icon-position-open'
          }`}
        >
          <i className={`mobile-sound-btn-icon ${playing ? 'audio-icon-mute' : ' audio-icon-active'}`} />
        </div>
      </div>
    </div>
  );
};

export default PlayerMobile;
