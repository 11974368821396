export enum EnumPagesTitles {
  ViewerPage = 'Viewer',

  // Other pages
  PageNotFound = 'PageNotFound',
  TourNotFound = 'TourNotFound',
  TourProtected = 'TourProtected',
}

export enum EnumCategory {
  Header = 'Header',
  Sidebar = 'Sidebar',

  // Password protected
  PasswordModal = 'PasswordModal',

  // Leads generations form
  FormCloseButton = 'FormCloseButton',
  FormSubmitButton = 'FormSubmitButton',

  // Leads generations compulsory
  FormSubmitButtonCompulsory = 'FormSubmitButtonCompulsory',

  // Start custom analytics
  UserOwnAnalytics = 'UserOwnAnalytics',

  // Tour Navigation
  TourNavigation = 'TourNavigation'
}

export enum EnumEvent {
  ZoomIn = 'Zoom in',
  ZoomOut = 'Zoom Out',
  EnterExitFullscreen = 'Enter/exit fullscreen',
  EnableDisableAudio = 'Enable/disable audio',
  DisplayMeasurementsDimensions = 'Display measurements/dimensions',
  ViewFloorplan = 'View floorplan',
  ViewDollshouse = 'View dollshouse',
  ShowHideThumbnailsBar = 'Show/hide the thumbnails bar',
  OpenStreetview = 'Open streetview',

  // Password protected
  UnlockVirtualTour = 'Unlock virtual tour',

  // Leads generations optional
  CloseFormWindow = 'Close form window',
  SendRequestOptional = 'Send request optional',

  // Leads generations compulsory
  SendRequestCompulsory = 'Send request compulsory',

  // Google analytics
  InitiateTourAnalyticsPrivately = 'InitiateTourAnalyticsPrivately',

  // Tour Navigation
  HotspotNavigation = 'Hotspot Navigation',
  ThumbnailsNavigation = 'Thumbnails Navigation',
  FloorPlanHotspotNavigation = 'FloorPlan Hotspot Navigation'
}

