import './custom-select.scss';
// import arrow from '../../../images/dashboard-icons/select-arrow.svg';
import React, { MouseEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import { ICurrentPanoramaVersion } from '../../../components/Viewer/Interfaces';
import { getDateNoTime, getFormattedDate } from '../../general/general-utils';

interface IProps {
  value: ITimes | undefined;
  options?: ICurrentPanoramaVersion;
  changeDropdownValue?: (value: string) => void;
  showCustomDropDown: boolean;
  setShowCustomDropDown?: (option?: boolean) => void;
  top: string;
  left: string;
  currentAlternativeDate?: Date;
  versionPickerRef?: MutableRefObject<HTMLDivElement>;
}

interface ITimes {
  id: string;
  updatedAt: string;
  date?: Date;
}

const CustomSelect: React.FC<IProps> = (props: IProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);
  const [availableTimes, setAvailableTimes] = useState<ITimes[]>();
  const [showScrollArrows, setShowScrollArrows] = useState(false);

  useEffect(() => {
    if (props.options) {
      let times: ITimes[] = [];
      // eslint-disable-next-line
      Object.keys(props.options).map(key => {
        if (props.options) {
          const updatedFormattedDate = getFormattedDate(props.options[key].updatedAt);
          if (props.currentAlternativeDate) {
            const currentSelectedDate = getDateNoTime(props.currentAlternativeDate);
            const versionDate = getDateNoTime(props.options[key].updatedAt);
            if (currentSelectedDate === versionDate) {
              times.push({ id: props.options[key].id, updatedAt: updatedFormattedDate });
            }
          }
        }
      });
      setAvailableTimes(times);
      if (Object.keys(times).length > 7) {
        setShowScrollArrows(true);
      } else {
        setShowScrollArrows(false);
      }
    }
  }, [props.options, props.currentAlternativeDate]);

  useEffect(() => {
    const onClick = (e: Event & { target: HTMLInputElement }) => {
      // If the active element exists and is clicked outside of
      const wrapper = props.versionPickerRef ? props.versionPickerRef : wrapperRef;
      if (wrapper.current !== null && !wrapper.current.contains(e.target)) {
        if (props.setShowCustomDropDown) {
          props.setShowCustomDropDown(false);
        }
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (props.showCustomDropDown) {
      window.addEventListener('click', onClick as EventListener);
    }

    return () => {
      window.removeEventListener('click', onClick as EventListener);
    };
    // eslint-disable-next-line
  }, [props.showCustomDropDown, wrapperRef, props.versionPickerRef]);

  const scrollTimes = (direction: string) => {
    const scrollOffset = 100;
    if (sliderRef.current) {
      if (direction === 'up') {
        sliderRef.current.scrollTop -= scrollOffset;
      } else {
        sliderRef.current.scrollTop += scrollOffset;
      }
    }
  };

  return (
    <div
      ref={props.versionPickerRef ? props.versionPickerRef : wrapperRef}
      className='custom-select-container'
      style={{ top: props.top, left: props.left }}
    >
      <button
        className={props.showCustomDropDown ? 'custom-select-active' : 'custom-select'}
        style={{ cursor: props.options ? 'pointer' : 'default' }}
        onMouseDown={(e: MouseEvent) => {
          e.preventDefault();
          if (availableTimes?.length && props.setShowCustomDropDown) {
            props.setShowCustomDropDown(false);
          }
        }}
      >
        <i className='far fa-stopwatch' style={{ marginRight: '10px' }}></i>
        {props.value?.updatedAt}
      </button>
      {availableTimes?.length && (
        <div>
          <div className='custom-dropdown' style={{ visibility: props.showCustomDropDown ? 'visible' : 'hidden' }}>
            {showScrollArrows && (
              <div className='custom-arrows' onClick={() => scrollTimes('up')}>
                <i className='far fa-angle-up' style={{ fontSize: '25px' }}></i>
              </div>
            )}
            <div ref={sliderRef} style={{ maxHeight: '260px', overflow: 'hidden', scrollBehavior: 'smooth', padding: '10px 0' }}>
              {availableTimes?.map((option, index) => {
                if (props.value?.id === option.id) {
                  return (
                    <button
                      key={index}
                      style={{ cursor: props.showCustomDropDown ? 'pointer' : 'default' }}
                      className='custom-dropdown-option-active'
                    >
                      {option.updatedAt}
                    </button>
                  );
                } else {
                  return (
                    <button
                      key={index}
                      style={{ cursor: props.showCustomDropDown ? 'pointer' : 'default' }}
                      className='custom-dropdown-option'
                      onMouseDown={(e: MouseEvent) => {
                        e.preventDefault();
                        if (props.changeDropdownValue) {
                          props.changeDropdownValue(option.id);
                        }
                      }}
                    >
                      {option.updatedAt}
                    </button>
                  );
                }
              })}
            </div>
            {showScrollArrows && (
              <div className='custom-arrows' onClick={() => scrollTimes('down')}>
                <i className='far fa-angle-down' style={{ fontSize: '25px' }}></i>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
