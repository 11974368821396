import React from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import ViewerPage from './pages/ViewerPage';
import BadRequestPage from './pages/BadRequestPage';
import useScript from './utils/custom-hooks/useScript';
import TestHealthPage from './components/ping-test/test-page-health';
import ConfirmationSent from './components/modal/ConfirmationSent';
import ConfirmationSentMobile from './components/modal/ConfirmationSentMobile';

import ModalShareMobile from './components/modal/ModalShareMobile';

const App: () => JSX.Element = () => {
  // Set google street view
  const getAddressApiKey = window.ENV.REACT_APP_GOOGLE_MAPS_KEY || process.env.REACT_APP_GOOGLE_MAPS_KEY;

  const googleScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${getAddressApiKey}&callback=Function.prototype`;

  useScript(googleScriptUrl);

  return (
    <div className='App'>
      <Routes>
        <Route path='/ping' element={<TestHealthPage />} />
        <Route path='/' element={<BadRequestPage />} />
        <Route path='/viewer/:readCode/:mode' element={<ViewerPage />} />
        <Route path='/viewer/:readCode' element={<ViewerPage />} />
        <Route path='/email-confirmation' element={<ConfirmationSent />} />
        <Route path='/email-confirmation-mobile' element={<ConfirmationSentMobile />} />
        <Route path='*' element={<BadRequestPage />} />
        <Route path='/create-live-meeting' element={<ModalShareMobile />} />
      </Routes>
    </div>
  );
};

export default App;
