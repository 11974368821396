import { useState } from 'react';

const useModalHotspot = () => {
  const [visibleHotspot, setVisible] = useState(false);
  function toggleHotspot() {
    setVisible(!visibleHotspot);
  }
  return { toggleHotspot, visibleHotspot };
};

export default useModalHotspot;
