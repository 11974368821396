export enum EnumEffects {
    zoomIn = 'ZoomIn',
    fadeIn = 'FadeIn'
}

export enum EnumDurationSpeed {
    default = 800,
    fast = 500,
    medium = 1000,
    slow = 1500
}

export enum EnumPanoramaVersionStatus {
    Deleted = -1,
    Unselected = 0,
    Selected = 1,
}