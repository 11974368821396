import ReactGA from "react-ga4";

// Setup Google Analytics to track your React.js
export const GoogleAnalyticsInit = (): void => {
  const trackingId =
    window.ENV.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || null;

  if (!trackingId || trackingId.length < 1) {
    return;
  }

  // This should be part of your setup
  ReactGA.initialize(trackingId);
};

// Setup Google Analytics to track your React.js
export const UserGoogleAnalyticsInit = (analyticsTrackingId: string): void => {
  // This should be part of your setup
  ReactGA.initialize(analyticsTrackingId);
};


//Report page view
export const ReportPageView = (svPageName: string): void => {
  // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: svPageName });
};

//To Report custom event
export const ReportCustomEvent = (category: string, action: string): void => {
  ReactGA.event({
    category: category,
    action: action
  });
};