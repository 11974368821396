import React from 'react';
import './loading-spinner.scss';
import smallVieweet from '../../images/vieweet-logo.svg';

const LoadingSpinner: React.FC = () => {
  return (
    <div className='bg-loader-smaller'>
      <div className='image-loading-spinner'>
        <img src={smallVieweet} alt='spinner' />
        <div className='loader'></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
