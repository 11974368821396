export class ValidationUtils {
    public static isBoolean<T>(value: T): boolean {
        if (typeof value === 'boolean') {
            return true;
        }
        return false;
    }

    public static isArrayNotEmpty<T>(array: T[]): boolean {
        if (Array.isArray(array) && array.length) {
            return true;
        }
        return false;
    }

    public static isGeoCoordinatesValid(latitude: number, longitude: number): boolean {
        let validLat = true;
        let validLong = true;
        const isValid = (currentValue: boolean) => currentValue === true;
        // latitude
        if (latitude < -90 || latitude > 90 || latitude === 0) {
            validLat = false;
        }
        // longitude
        if (longitude < -180 || longitude > 180 || longitude === 0) {
            validLong = false;
        }
        return [validLat, validLong].every(isValid);
    }
}
