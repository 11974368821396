import { ClientApi } from '@vva/front';
import { IViewerPannellumData, IViewerPannellumProtectedData } from '../components/Viewer/Interfaces';

const clientApi = new ClientApi();


// conditional window ENV for testing with jest
clientApi.setHeaders({
  'X-Api-Key':
    (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY
});


/**
 * Get standalone
 * @returns tour data extended
 */
export const getStandaloneTour = async (readCode: string, password?: string): Promise<IViewerPannellumData | IViewerPannellumProtectedData> => {
  const body = password && password.length ? { password } : {};
  let url = `${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/standalone/tour/${readCode}`;
  const params = new URLSearchParams(window.location.search);

  // Force image environment
  const imageEnvParam = params.get('imageEnv');
  if (imageEnvParam && imageEnvParam.length) {
    url += "?imageEnv=" + imageEnvParam;
  }

  const response = await clientApi.postData(url, body);
  const placeTripodParam = params.get('tripod');

  if(placeTripodParam === "true") {
	const tempData = response.data;
	for(const id in tempData.panoramas) {
	  const panorama = tempData.panoramas[id];
	  panorama.hotSpots.push({
		  pitch: -90,
		  yaw: 0,
		  targetYaw: 0,
		  cssClass: 'tripod',
		  createTooltipFunc: 'tripod',
		  createTooltipArgs: {
			  src: 'https://vieweet360-viewer.s3.eu-west-2.amazonaws.com/tripod-cap/vieweet-default-cap.svg',
		  }
	  });
	}
	return tempData;
  } else {
	return response.data;
  }  
};
