import React, { useEffect } from 'react';
import BadRequest from '../components/BadRequest/BadRequest';

import { ReportPageView } from '../GoogleAnalyticsConfig';
import { EnumPagesTitles } from '../GoogleAnalyticsTrackList';

const BadRequestPage = (): JSX.Element => {
  useEffect(() => {
    // componentDidMount
    //Google report page
    ReportPageView(EnumPagesTitles.PageNotFound);

    // componentWillUnmount
    return () => {
      //
    };
  }, []);

  return <BadRequest />;
};
export default BadRequestPage;
