import { useState, useEffect, useCallback } from 'react';
import './sound-player.scss';
import { i18n } from '@lingui/core';
import { isMobile } from 'react-device-detect';

interface Props {
  url: string;
  audioLoop?: boolean;
  audioPlay?: boolean;
}

const useAudio = (url: string, audioLoop?: boolean, audioPlay?: boolean): [boolean, () => void] => {
  const [audio, setAudio] = useState<HTMLAudioElement>();
  const [playing, setPlaying] = useState<boolean>(false);
  const [loop] = useState(audioLoop ? audioLoop : false);

  const toggle = useCallback((): void => setPlaying(state => !state), []);

  useEffect(() => {
    if (url) {
      setAudio(new Audio(url));
    }
    // eslint-disable-next-line
  }, [url]);

  const autoPlayEvent = () => {
    if (audioPlay) {
      setPlaying(true);
      document.removeEventListener('click', autoPlayEvent);
    }
  };

  useEffect(() => {
    document.addEventListener('click', autoPlayEvent);
    // eslint-disable-next-line
  }, [url]);

  useEffect(() => {
    if (audio) {
      audio.loop = loop;
      playing && !isMobile ? audio.play() : audio.pause();
    }

    // Stops audio when panorama is changed
    return () => {
      if (audio) {
        audio.pause();
      }
    };
    // eslint-disable-next-line
  }, [playing, audio]);

  return [playing, toggle];
};

const Player = ({ url, audioLoop, audioPlay }: Props) => {
  const [playing, setPlaying] = useAudio(url, audioLoop, audioPlay);

  return (
    <div id='sound'>
      {!url ? (
        <div onClick={setPlaying}>
          <div className='sounds' title={String(i18n.messages.control_bar_sounds)}>
            <i className='sounds-icon-disable' />
          </div>
        </div>
      ) : (
        <div onClick={setPlaying}>
          <div className='sounds' title={String(i18n.messages.control_bar_sounds)}>
            <i className={`sounds-icon ${playing ? 'sounds-icon' : 'sounds-icon-mute'}`} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Player;
