/* eslint-disable react/display-name */
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { i18n } from '@lingui/core';
import { ReportCustomEvent } from '../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../GoogleAnalyticsTrackList';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import './Toolbar.scss';

// Active icons
import floorPlanActive from '../../images/toolbar/floorplan.svg';
import dollHouseActive from '../../images/toolbar/dollhouse.svg';

// On click icons
import dollHouseBlue from '../../images/toolbar/dollhouse-blue.svg';

// Disable icons
import dollHouseDisable from '../../images/toolbar/dollhouse-disable.svg';
import PanoramaSlider from './PanoramaSlider/PanoramaSlider';
import { IViewerPannellumPanoramaSound, IViewerPannellumThumbnail } from '../Viewer/Interfaces';
import PlayerMobile from '../../utils/SoundPlayer/SoundPlayerMobile';

interface Props {
  thumbNailsList: IViewerPannellumThumbnail[];
  onChangeScene: (id: string, targetYaw: number) => void;
  onDisplay: boolean;
  onShowScene: () => void;
  onStartStreetView: () => void;
  onStartViewer: () => void;
  currentRoomId: string;
  onDisplayFloorPlan: () => void;
  isFloorPlan: boolean | true;
  isStreetView: boolean | true;
  showMobileMenu: boolean | false;
  onMobileMenu: () => void;
  isMobileStreetView: boolean | false;
  sounds?: IViewerPannellumPanoramaSound[];
  splitScreenAvailable: boolean;
  toggleSplitScreen: boolean;
  onSplitView: () => void;
}

type buttonStates = {
  disable: boolean | false;
  state: boolean | false;
};

type audioButtonState = {
  disable: boolean | false;
  state: boolean | false;
  audioUrl?: string;
  audioLoop?: boolean;
  audioPlay?: boolean;
};

interface RefObject {
  closeFloorPlan: () => void;
}

/**
 * Toolbar component
 * @param props
 * @returns Toolbar component
 * @category Component
 */
const Toolbar = forwardRef((props: Props, ref: Ref<RefObject>): JSX.Element => {
  const [floorPlan, setFloorPlan] = useState<buttonStates>({ disable: false, state: false });
  const [dollHouse, setDollHouse] = useState<buttonStates>({ disable: true, state: false });
  const [thumbnails, setThumbnails] = useState<buttonStates>({ disable: false, state: false });
  const [streetView, setStreetView] = useState<buttonStates>({ disable: false, state: false });
  const [rightMenu, setRightMenu] = useState(false);
  const [slider, setSlider] = useState(false);
  // Sounds
  const [audio, setAudio] = useState<audioButtonState>({ disable: false, state: false });

  const [streetViewState, setStreetViewState] = useState({ floorPlan: false, dollHouse: false, thumbnails: false });
  const mobileOrientation = useMobileOrientation();

  useEffect(() => {
    if (props && props.onDisplay) {
      setThumbnails(prevState => ({
        ...prevState,
        state: props.onDisplay,
      }));
    }
    // eslint-disable-next-line
  }, [props.onDisplay]);

  useEffect(() => {
    if (props && props.sounds) {
      const sound = props.sounds.find((sound: IViewerPannellumPanoramaSound) => sound.panoramaId === props.currentRoomId);
      if (sound) {
        setAudio({
          disable: false,
          state: true,
          audioLoop: Boolean(sound.audioLoop),
          audioPlay: Boolean(sound.audioPlay),
          audioUrl: sound.audioUrl,
        });
      }
    }
  }, [props]);

  useEffect(() => {
    setFloorPlan(prevState => ({
      ...prevState,
      disable: props.isFloorPlan,
    }));
    setStreetView(prevState => ({
      ...prevState,
      disable: props.isStreetView,
    }));
    return () => {
      //
    };
  }, [props.isFloorPlan, props.isStreetView]);

  const handleSetFloorPlan = () => {
    updateValues('1');
    ReportCustomEvent(EnumCategory.Sidebar, EnumEvent.ViewFloorplan);
  };

  const handleSetDollHouse = () => {
    updateValues('2');
    ReportCustomEvent(EnumCategory.Sidebar, EnumEvent.ViewDollshouse);
  };

  const handleSetThumbnails = () => {
    updateValues('3');
    props.onShowScene();
    if (mobileOrientation.isLandscape) {
      setSlider(!slider);
    }
    ReportCustomEvent(EnumCategory.Sidebar, EnumEvent.ShowHideThumbnailsBar);
  };

  const handleSetStreetView = () => {
    updateValues('4');
    ReportCustomEvent(EnumCategory.Sidebar, EnumEvent.OpenStreetview);
  };

  const updateValues = (value: string) => {
    switch (value) {
      case '1':
        // code block
        const floorPlanValue = !floorPlan.state;
        setFloorPlan(prevState => ({
          ...prevState,
          state: floorPlanValue,
        }));
        setThumbnails(prevState => ({
          ...prevState,
          state: false,
        }));
        setDollHouse(prevState => ({
          ...prevState,
          state: false,
        }));
        setStreetView(prevState => ({
          ...prevState,
          state: false,
        }));
        if (thumbnails.state) {
          props.onShowScene();
        }

        //FloorPlan
        props.onDisplayFloorPlan();
        break;
      case '2':
        // code block
        const dollHouseValue = !dollHouse.state;
        setDollHouse(prevState => ({
          ...prevState,
          state: dollHouseValue,
        }));

        setFloorPlan(prevState => ({
          ...prevState,
          state: false,
        }));
        setThumbnails(prevState => ({
          ...prevState,
          state: false,
        }));
        setStreetView(prevState => ({
          ...prevState,
          state: false,
        }));
        if (thumbnails.state) props.onShowScene();

        //FloorPlan
        if (floorPlan.state) props.onDisplayFloorPlan();

        break;
      case '3':
        // code block
        const thumbnailsValue = !thumbnails.state;
        setThumbnails(prevState => ({
          ...prevState,
          state: thumbnailsValue,
        }));
        setFloorPlan(prevState => ({
          ...prevState,
          state: false,
        }));
        setStreetView(prevState => ({
          ...prevState,
          state: false,
        }));
        setDollHouse(prevState => ({
          ...prevState,
          state: false,
        }));
        if (thumbnails.state) props.onShowScene();

        //FloorPlan
        if (floorPlan.state) props.onDisplayFloorPlan();
        break;
      case '4':
        // code block
        const streetViewValue = !streetView.state;
        setStreetView(prevState => ({
          ...prevState,
          state: streetViewValue,
        }));

        if (thumbnails.state) props.onShowScene();
        //FloorPlan
        if (floorPlan.state) props.onDisplayFloorPlan();

        if (streetViewValue) {
          props.onStartStreetView();

          setStreetViewState(prevState => ({
            ...prevState,
            floorPlan: floorPlan.disable,
            dollHouse: dollHouse.disable,
            thumbnails: thumbnails.disable,
          }));

          if (isMobile) {
            setFloorPlan(prevState => ({
              ...prevState,
              state: false,
              // disable: true,
            }));
            setThumbnails(prevState => ({
              ...prevState,
              state: false,
              disable: true,
            }));
            setDollHouse(prevState => ({
              ...prevState,
              state: false,
              disable: true,
            }));
          }
        } else {
          props.onStartViewer();
          setFloorPlan(prevState => ({
            ...prevState,
            state: false,
            disable: streetViewState.floorPlan,
          }));
          setThumbnails(prevState => ({
            ...prevState,
            state: false,
            disable: streetViewState.thumbnails,
          }));
          setDollHouse(prevState => ({
            ...prevState,
            state: false,
            disable: streetViewState.dollHouse,
          }));
        }
        break;
      default:
      // code block
    }
  };

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    closeFloorPlan() {
      handleSetFloorPlan();
    },
  }));

  return (
    <React.Fragment>
      {/* -------------------  Panorama Slider --------------------------------- */}
      <PanoramaSlider
        onDisplay={props.onDisplay}
        onChangeScene={props.onChangeScene}
        thumbNailsList={props.thumbNailsList}
        currentRoomId={props.currentRoomId}
      />
      {/* -------------------  Panorama Slider --------------------------------- */}
      <div className='nav-bar'>
        {props.splitScreenAvailable && (
          <>
            <div className='available-versions-list-container'></div>
            <div
              className={`split-screen-icon ${
                props.toggleSplitScreen ? 'split-on-container split-on-icon' : 'split-off-container split-off-icon'
              }`}
              onClick={() => props.onSplitView()}
            >
              <i className='fal fa-columns'></i>
            </div>
          </>
        )}
        <div
          className={`floor-plan-div ${floorPlan.disable ? 'disable' : floorPlan.state ? 'selected' : 'active'}`}
          onClick={() => handleSetFloorPlan()}
          title={String(i18n.messages.toolbar_floorPlan_icon)}
        >
          <div className={`floorplan-images ${floorPlan.disable ? 'disable' : floorPlan.state ? 'selected' : floorPlanActive} `} />
        </div>
        <div
          className={`doll-house-div ${dollHouse.disable ? 'disable' : dollHouse.state ? 'selected' : 'active'}`}
          onClick={() => handleSetDollHouse()}
          title={String(i18n.messages.toolbar_dollHouse_icon)}
        >
          <img src={dollHouse.disable ? dollHouseDisable : dollHouse.state ? dollHouseBlue : dollHouseActive} alt='Doll House' />
        </div>
        <div
          className={`thumbnails-div ${thumbnails.disable ? 'disable' : thumbnails.state ? 'selected' : 'active'}`}
          onClick={() => handleSetThumbnails()}
          title={String(i18n.messages.toolbar_thumbnails_icon)}
        >
          <div className={`thumbnails-images ${thumbnails.disable ? 'disable' : thumbnails.state ? 'selected' : ''} `} />
        </div>
        <div
          className={`street-view-div ${streetView.disable ? 'disable' : streetView.state ? 'selected' : 'active'}`}
          onClick={() => handleSetStreetView()}
          title={
            streetView.disable
              ? String(i18n.messages.toolbar_streetView_icon)
              : streetView.state
              ? String(i18n.messages.toolbar_virtualTour_icon)
              : String(i18n.messages.toolbar_streetView_icon)
          }
        >
          <div className={`street-view-images ${streetView.disable ? 'disable' : streetView.state ? 'selected' : 'active'}`} />
        </div>
      </div>
      {!props.isMobileStreetView ? (
        <div
          className={`mobile-thumbnails-img ${
            thumbnails.state
              ? 'mobile-thumbnails-img-pressed mobile-thumbnails-icon-position-open'
              : 'mobile-thumbnails-img-active mobile-thumbnails-icon-position-close'
          }`}
          onClick={() => {
            // Toggles thumbnails
            setRightMenu(false);
            if (rightMenu) {
              props.onMobileMenu();
            }
            handleSetThumbnails();
          }}
        >
          <i className={`thumbnail-icon ${thumbnails.state ? 'mobile-thumbnails-img-icon-active' : 'mobile-thumbnails-img-icon'}`} />
        </div>
      ) : null}
      {/* {!streetView.state  ? ( */}
      {!props.isMobileStreetView && (!floorPlan.disable || !streetView.disable || (audio.state && audio.audioUrl)) ? (
        <React.Fragment>
          {!floorPlan.disable ? (
            <div
              className={`mobile-floor-plan-btn ${
                slider
                  ? 'mobile-floorplan-landscape'
                  : floorPlan.state
                  ? 'floorplan-img-pressed floorplan-icon-position-open'
                  : 'floorplan-img-active floorplan-icon-position-close'
              }`}
              onClick={() => handleSetFloorPlan()}
            >
              <i className={`floorplan-icon ${floorPlan.state ? 'mobile-floor-plan-btn-icon-active' : 'mobile-floor-plan-btn-icon'}`} />
            </div>
          ) : null}
          {!streetView.disable ? (
            <div
              className={`mobile-street-view-btn ${
                slider
                  ? 'mobile-streetview-landscape'
                  : props.isMobileStreetView
                  ? 'street-view-img-pressed street-view-icon-position-open'
                  : 'street-view-img-active street-view-icon-position-close'
              }`}
              onClick={() => handleSetStreetView()}
            >
              <i className={`street-view-icon ${streetView.state ? 'street-view-icon-active' : 'street-view-icon'}`} />
            </div>
          ) : null}
          {audio.state && audio.audioUrl ? (
            <PlayerMobile slider={slider} url={audio.audioUrl} audioLoop={audio.audioLoop} audioPlay={audio.audioPlay} />
          ) : null}

          {floorPlan.state && (
            <button onClick={() => handleSetFloorPlan()}>
              <i className='mobile-cross-icon' />
            </button>
          )}
          <div
            className={` ${props.showMobileMenu ? 'mobile-menu-pressed' : 'mobile-menu-active'} ${
              thumbnails.state ? 'mobile-thumbnails-icon-position-open' : 'mobile-thumbnails-icon-position-close'
            }`}
            onClick={() => {
              // Open options menu
              if (thumbnails && thumbnails.state) {
                handleSetThumbnails();
              }
              setRightMenu(!rightMenu);
              props.onMobileMenu();
            }}
          >
            <i className={props.showMobileMenu ? 'mobile-menu-icon-active' : 'mobile-menu-icon'} />
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
});

export default Toolbar;
