import React from 'react';
import './TourNotFound.scss';
import virtualTourNotFound from '../../images/empty-state/virtual-tour-not-found.svg';

/**
 * NotFound component
 * @param props
 * @returns NotFound component
 * @category Component
 */
const NotFound = (): JSX.Element => {
  return (
    <div className={'container not-found-layout'}>
      <div className='container-tour-not-found'>
        <img className='not-tour-found-img' src={virtualTourNotFound} alt='404 error state'></img>
        <div className='container-texte-not-found'>
          <h1 className='not-tour-found-title'>Virtual tour not found </h1>
          <p className='not-found-tour-text'>The virtual tour you’re looking for has been deleted! </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
