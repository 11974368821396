import React, { useEffect, useState } from 'react';
import starEmpty from '../../images/ratings/star-empty.svg';
import starHover from '../../images/ratings/star-hover.svg';
import starActive from '../../images/ratings/star-active.svg';
import './Ratings.scss';
import {
  createPropertyRating,
  createRoomRating,
  deletePropertyRating,
  deleteRoomRating,
  getPropertyRating,
  getRoomRating,
} from '../../services/ratingsService';

interface IProps {
  tourId: string;
  currentRoomId: string;
  ratingToken: string;
}

/**
 * Ratings component
 * @param props
 * @returns Ratings component
 * @category Component
 */
const Ratings = ({ tourId, currentRoomId, ratingToken }: IProps) => {
  // Room rating
  const [ratingRoom, setRatingRoom] = useState<number>(0);
  const [ratingRoomActive, setRatingRoomActive] = useState<number>(0);
  const [ratingRoomActiveDisplay, setRatingRoomActiveDisplay] = useState<boolean>(false);
  const [loadingRoom, setLoadingRoom] = useState(false);
  // Property rating
  const [ratingProperty, setRatingProperty] = useState<number>(0);
  const [ratingPropertyActive, setRatingPropertyActive] = useState<number>(0);
  const [ratingPropertyActiveDisplay, setRatingPropertyActiveDisplay] = useState<boolean>(false);
  const [loadingProperty, setLoadingProperty] = useState(false);

  // const [showError, setShowError] = useState<boolean>(false);
  // const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (tourId) {
      getTourRatingAsync();
    }
    // eslint-disable-next-line
  }, [tourId]);

  useEffect(() => {
    if (currentRoomId) {
      getRoomRatingAsync();
    }
    // eslint-disable-next-line
  }, [currentRoomId]);

  const getTourRatingAsync = async () => {
    try {
      const rating = await getPropertyRating(tourId, ratingToken);
      if (rating && rating.tourValue) {
        setRatingPropertyActiveDisplay(true);
        setRatingPropertyActive(+rating.tourValue);
      } else {
        setRatingPropertyActiveDisplay(false);
        setRatingPropertyActive(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRoomRatingAsync = async () => {
    try {
      const rating = await getRoomRating(currentRoomId, ratingToken);
      if (rating && rating.panoramaValue) {
        setRatingRoomActiveDisplay(true);
        setRatingRoomActive(+rating.panoramaValue);
      } else {
        setRatingRoomActiveDisplay(false);
        setRatingRoomActive(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStarViewRoom = (value: number, active?: boolean) => {
    if (active) {
      if (value > ratingRoomActive) {
        return starEmpty;
      } else {
        return starActive;
      }
    } else {
      if (value > ratingRoom) {
        return starEmpty;
      } else {
        return starHover;
      }
    }
  };

  const getStarViewProperty = (value: number, active?: boolean) => {
    if (active) {
      if (value > ratingPropertyActive) {
        return starEmpty;
      } else {
        return starActive;
      }
    } else {
      if (value > ratingProperty) {
        return starEmpty;
      } else {
        return starHover;
      }
    }
  };

  const createPropertyRatingClick = async (rating: number) => {
    if (ratingPropertyActive === 0) {
      setLoadingProperty(true);
      try {
        await createPropertyRating(tourId, rating, ratingToken);
        setRatingPropertyActiveDisplay(true);
        setRatingPropertyActive(rating);
        setLoadingProperty(false);
      } catch (error) {
        setLoadingProperty(false);
        // setErrorMessage('404 Not Found');
        // setShowError(true);
        console.log(error);
      }
    }
  };

  const createRoomRatingClick = async (rating: number) => {
    if (ratingRoomActive === 0) {
      setLoadingRoom(true);
      try {
        await createRoomRating(tourId, currentRoomId, rating, ratingToken);
        setRatingRoomActiveDisplay(true);
        setRatingRoomActive(rating);
        setLoadingRoom(false);
      } catch (error) {
        setLoadingRoom(false);
        // setErrorMessage('404 Not Found');
        // setShowError(true);
        console.log(error);
      }
    }
  };

  const removeRoomRating = async () => {
    setLoadingRoom(true);
    try {
      await deleteRoomRating(tourId, currentRoomId, ratingToken);
      setRatingRoomActive(0);
      setRatingRoomActiveDisplay(false);
      setLoadingRoom(false);
    } catch (error) {
      setLoadingRoom(false);
      console.log(error);
    }
  };

  const removePropertyRating = async () => {
    setLoadingProperty(true);
    try {
      await deletePropertyRating(tourId, ratingToken);
      setRatingPropertyActive(0);
      setRatingPropertyActiveDisplay(false);
      setLoadingProperty(false);
    } catch (error) {
      setLoadingProperty(false);
      console.log(error);
    }
  };

  return (
    <>
      {/* <Notification type={EnumNotificationType.Error} text={errorMessage} display={showError} toggleDisplay={setShowError} /> */}
      <div className='room-rating-container'>
        <div className='room-quality-rating'>What is your general impression of the room quality?</div>
        <div className='room-quality-stars-container'>
          <img
            className='rating-stars'
            onClick={() => createRoomRatingClick(1)}
            onMouseLeave={() => {
              setRatingRoom(0);
              setRatingRoomActiveDisplay(true);
            }}
            onMouseEnter={() => {
              setRatingRoomActiveDisplay(false);
              setRatingRoom(1);
            }}
            src={ratingRoomActiveDisplay ? getStarViewRoom(1, true) : getStarViewRoom(1)}
            alt='star'
          />
          <div className='divider-ratings'></div>
          <img
            className='rating-stars'
            onClick={() => createRoomRatingClick(2)}
            onMouseLeave={() => {
              setRatingRoomActiveDisplay(true);
              setRatingRoom(0);
            }}
            onMouseEnter={() => {
              setRatingRoomActiveDisplay(false);
              setRatingRoom(2);
            }}
            src={ratingRoomActiveDisplay ? getStarViewRoom(2, true) : getStarViewRoom(2)}
            alt='star'
          />
          <img
            className='rating-stars'
            onClick={() => createRoomRatingClick(3)}
            onMouseLeave={() => {
              setRatingRoomActiveDisplay(true);
              setRatingRoom(0);
            }}
            onMouseEnter={() => {
              setRatingRoomActiveDisplay(false);
              setRatingRoom(3);
            }}
            src={ratingRoomActiveDisplay ? getStarViewRoom(3, true) : getStarViewRoom(3)}
            alt='star'
          />
          <img
            className='rating-stars'
            onClick={() => createRoomRatingClick(4)}
            onMouseLeave={() => {
              setRatingRoomActiveDisplay(true);
              setRatingRoom(0);
            }}
            onMouseEnter={() => {
              setRatingRoomActiveDisplay(false);
              setRatingRoom(4);
            }}
            src={ratingRoomActiveDisplay ? getStarViewRoom(4, true) : getStarViewRoom(4)}
            alt='star'
          />
          <div className='divider-ratings-green'></div>
          <img
            className='rating-stars'
            onClick={() => createRoomRatingClick(5)}
            onMouseLeave={() => {
              setRatingRoomActiveDisplay(true);
              setRatingRoom(0);
            }}
            onMouseEnter={() => {
              setRatingRoomActiveDisplay(false);
              setRatingRoom(5);
            }}
            src={ratingRoomActiveDisplay ? getStarViewRoom(5, true) : getStarViewRoom(5)}
            alt='star'
          />
          {ratingRoom !== 0 && (
            <div
              style={{
                left: ratingRoom === 1 ? 0 : ratingRoom === 2 ? 30 : ratingRoom === 3 ? 90 : ratingRoom === 4 ? 130 : 210,
                width: ratingRoom === 4 ? '120px' : ratingRoom === 2 || ratingRoom === 4 ? '100px' : '',
                height: ratingRoom === 2 ? '70px' : ratingRoom === 4 ? '70px' : '',
              }}
              className='rating-description'
            >
              {ratingRoom === 1
                ? 'Poor'
                : ratingRoom === 2
                ? 'Somewhat below average'
                : ratingRoom === 3
                ? 'Average'
                : ratingRoom === 4
                ? 'Somewhat above average'
                : 'Excellent'}
            </div>
          )}
        </div>
        {loadingRoom && <i id='loading-delete-room-rating' className='far fa-spinner fa-spin fa-2x fa-fw'></i>}
        <button disabled={ratingRoomActive === 0 ? true : false} className='remove-rating-room' onClick={() => removeRoomRating()}>
          Remove rating
        </button>
      </div>
      <div className='property-rating-container'>
        <div className='property-quality-rating'>What is your general impression of the property quality?</div>
        <div className='property-quality-stars-container'>
          <img
            className='rating-stars'
            onClick={() => createPropertyRatingClick(1)}
            onMouseLeave={() => {
              setRatingProperty(0);
              setRatingPropertyActiveDisplay(true);
            }}
            onMouseEnter={() => {
              setRatingPropertyActiveDisplay(false);
              setRatingProperty(1);
            }}
            src={ratingPropertyActiveDisplay ? getStarViewProperty(1, true) : getStarViewProperty(1)}
            alt='star'
          />
          <div className='divider-ratings'></div>
          <img
            className='rating-stars'
            onClick={() => createPropertyRatingClick(2)}
            onMouseLeave={() => {
              setRatingPropertyActiveDisplay(true);
              setRatingProperty(0);
            }}
            onMouseEnter={() => {
              setRatingPropertyActiveDisplay(false);
              setRatingProperty(2);
            }}
            src={ratingPropertyActiveDisplay ? getStarViewProperty(2, true) : getStarViewProperty(2)}
            alt='star'
          />
          <img
            className='rating-stars'
            onClick={() => createPropertyRatingClick(3)}
            onMouseLeave={() => {
              setRatingPropertyActiveDisplay(true);
              setRatingProperty(0);
            }}
            onMouseEnter={() => {
              setRatingPropertyActiveDisplay(false);
              setRatingProperty(3);
            }}
            src={ratingPropertyActiveDisplay ? getStarViewProperty(3, true) : getStarViewProperty(3)}
            alt='star'
          />
          <img
            className='rating-stars'
            onClick={() => createPropertyRatingClick(4)}
            onMouseLeave={() => {
              setRatingPropertyActiveDisplay(true);
              setRatingProperty(0);
            }}
            onMouseEnter={() => {
              setRatingPropertyActiveDisplay(false);
              setRatingProperty(4);
            }}
            src={ratingPropertyActiveDisplay ? getStarViewProperty(4, true) : getStarViewProperty(4)}
            alt='star'
          />
          <div className='divider-ratings-green'></div>
          <img
            className='rating-stars'
            onClick={() => createPropertyRatingClick(5)}
            onMouseLeave={() => {
              setRatingPropertyActiveDisplay(true);
              setRatingProperty(0);
            }}
            onMouseEnter={() => {
              setRatingPropertyActiveDisplay(false);
              setRatingProperty(5);
            }}
            src={ratingPropertyActiveDisplay ? getStarViewProperty(5, true) : getStarViewProperty(5)}
            alt='star'
          />
          {ratingProperty !== 0 && (
            <div
              style={{
                left: ratingProperty === 1 ? 0 : ratingProperty === 2 ? 30 : ratingProperty === 3 ? 90 : ratingProperty === 4 ? 130 : 180,
                width: ratingProperty === 4 ? '120px' : ratingProperty === 2 || ratingProperty === 4 ? '100px' : '',
                height: ratingProperty === 2 ? '70px' : ratingProperty === 4 ? '70px' : '',
              }}
              className='rating-description'
            >
              {ratingProperty === 1
                ? 'Poor'
                : ratingProperty === 2
                ? 'Somewhat below average'
                : ratingProperty === 3
                ? 'Average'
                : ratingProperty === 4
                ? 'Somewhat above average'
                : 'Excellent'}
            </div>
          )}
        </div>
        {loadingProperty && <i id='loading-delete-property-rating' className='far fa-spinner fa-spin fa-2x fa-fw'></i>}
        <button
          disabled={ratingPropertyActive === 0 ? true : false}
          className='remove-rating-property'
          onClick={() => removePropertyRating()}
        >
          Remove rating
        </button>
      </div>
    </>
  );
};

export default Ratings;
