import React from 'react';
import ReactDOM from 'react-dom';
import { EnumCategory, EnumEvent } from '../../../GoogleAnalyticsTrackList';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import './Modal.scss';
import { Trans } from '@lingui/react';
import { isMobileOnly } from 'react-device-detect';

interface Props {
  compulsory: boolean;
  visible: boolean;
  childComp?: JSX.Element;
  text: string;
  title?: string;
  onAccept?: () => void;
  onCancel: () => void;
  enableButtonGroup: boolean;
}

const Modal = (props: Props) => {
  const { childComp } = props;

  return props.visible
    ? ReactDOM.createPortal(
        <div className={isMobileOnly ? 'use-modal-mobile' : 'use-modal'}>
          <div className='modal-pop' role='dialog' aria-modal='true'>
            <div className={`${props.compulsory ? 'modal-title-compulsory' : 'modal-title'}`}>
              <p>
                <Trans id='Learn more' />
              </p>
              {!props.compulsory && (
                <div
                  className='close-btn-top'
                  onClick={() => {
                    props.onCancel();
                    ReportCustomEvent(EnumCategory.FormCloseButton, EnumEvent.CloseFormWindow);
                  }}
                >
                  <i className='close-btn-top-icon' />
                </div>
              )}
            </div>
            <div className='modal-content'>
              <div className='modal-content-text'>
                <div className='modal-content-text-message'>
                  <p className='title'>{props.title}</p>
                </div>
                <div className='modal-content-text-subtitle'>
                  <p className='main-text'>{props.text}</p>
                </div>
              </div>
              {childComp ? <div className='modal-child-element'>{childComp}</div> : null}
              {props.enableButtonGroup ? (
                <div className='btn-group'>
                  <button className='btn-accept' type='button' onClick={props.onAccept}>
                    <Trans id='Accept' />
                  </button>
                  <button className='btn-cancel' type='button' onClick={props.onCancel}>
                    <Trans id='Close' />
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div className='modal-overlay'></div>
        </div>,
        document.body,
      )
    : null;
};

export default Modal;
