import React, { CSSProperties, useEffect, useState } from 'react';
import './FloorPlan.scss';
import listReducerFloorPlan from './listReducerFloorPlan';
import { ImageUtils } from '../../utils/image-utils/image-utils';
import { isMobile } from 'react-device-detect';
import { IViewerPannellumFloorPlan, IViewerPannellumFloorPlanHotspot } from '../Viewer/Interfaces';
import { ReportCustomEvent } from '../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../GoogleAnalyticsTrackList';

interface Props {
  currentFloorPlanIndex: number;
  floorPlans: IViewerPannellumFloorPlan[];
  onChangeScene: (id: string, targetYaw: number) => void;
  currentFloorPlan: IViewerPannellumFloorPlan;
  displayFloorPlan: boolean | false;
  slideFloorPlan: (move: number) => void;
}

/**
 * FloorPlan component
 * @param props
 * @returns FloorPlan component
 * @category Component
 */
const FloorPlan = (props: Props): JSX.Element => {
  const [isFloorPlanMinimized, setIsFloorPlanMinimized] = useState(false);
  const newListReducer = listReducerFloorPlan();
  const [hotspots, setHotspots] = React.useReducer(newListReducer, { data: props.currentFloorPlan?.hotspots });
  const [isPortraitMode, setIsPortraitMode] = useState(false);

  useEffect(() => {
    const items = props.currentFloorPlan?.hotspots;
    setHotspots({ type: 'ADD_INITIAL', items });
    if (ImageUtils.checkImageOrientation(props.currentFloorPlan?.width, props.currentFloorPlan?.height) === 'portrait') {
      setIsPortraitMode(true);
    }

    return () => {
      //
    };
  }, [props.currentFloorPlan]);

  const onFloorPlanSize = () => {
    setIsFloorPlanMinimized(!isFloorPlanMinimized);
  };

  function updateHotspotState(hotpotActive: IViewerPannellumFloorPlanHotspot) {
    if (!isMobile && !isFloorPlanMinimized) {
      onFloorPlanSize();
    }
    const id = hotpotActive.id;
    const item = hotpotActive;
    setHotspots({ type: 'UPDATE_ITEMS', id, item });

    // Google analytics event
    ReportCustomEvent(EnumCategory.TourNavigation, EnumEvent.FloorPlanHotspotNavigation);

    props.onChangeScene(hotpotActive.destId, hotpotActive.targetYaw);
  }

  const items = () => {
    if (hotspots.data) {
      return hotspots.data.map((hotspot: IViewerPannellumFloorPlanHotspot, index: number) => {
        const hotspotStyle: CSSProperties = {
          left: hotspot.x,
          top: hotspot.y,
        };

        const hotspotClassName = hotspot.isActive
          ? `${isFloorPlanMinimized ? 'active-hotspot-minimized hotspot-minimized' : 'active-hotspot hotspot'}`
          : `${isFloorPlanMinimized ? 'hotspot-minimized' : 'hotspot'}`;

        return (
          <div key={index} className='hotspot-preview-container' onClick={() => updateHotspotState(hotspot)}>
            <div className={hotspotClassName} style={hotspotStyle}>
              <span data-name={hotspot.roomName} data-scene-id={hotspot.destId} />
            </div>
            <div style={hotspotStyle} className='hover-preview'>
              <img className='preview-image' src={hotspot.url} alt='panorama' />
              <div>{hotspot.roomName}</div>
            </div>
          </div>
        );
      });
    } else {
      return [];
    }
  };

  return (
    <React.Fragment>
      <div
        className={
          props.displayFloorPlan
            ? `${
                isFloorPlanMinimized
                  ? `plan-minimized panel ${isPortraitMode ? 'panel-floor-plan-portrait-minimized' : 'panel-floor-plan-minimized'}`
                  : 'plan panel panel-floor-plan'
              } right`
            : `${
                isFloorPlanMinimized
                  ? `plan-minimized panel ${isPortraitMode ? 'panel-floor-plan-portrait-minimized' : 'panel-floor-plan-minimized'}`
                  : 'plan panel panel-floor-plan'
              } left`
        }
      >
        <div
          className='left-floor-plan-button'
          style={{ visibility: props.currentFloorPlanIndex > 0 ? 'visible' : 'hidden' }}
          onClick={() => props.slideFloorPlan(-1)}
        >
          <i className='left-floor-plan-button-img' />
        </div>

        <div
          className={
            isFloorPlanMinimized
              ? isPortraitMode
                ? 'floor-plan-container-portrait-minimized'
                : 'floor-plan-container-minimized'
              : isPortraitMode
              ? 'floor-plan-container-portrait'
              : 'floor-plan-container'
          }
        >
          <img className='imgL' src={props.currentFloorPlan?.url} alt='Property Floor Plan' />
          {/*map of spans to populate the hotspots */}
          {items()}
        </div>
        <div
          className={
            isFloorPlanMinimized
              ? isPortraitMode
                ? 'right-buttons-group-portrait-minimized'
                : 'right-buttons-group-minimized'
              : isPortraitMode
              ? 'right-buttons-group-portrait'
              : 'right-buttons-group'
          }
        >
          <div className='right-floor-plan-fullscreen-button' onClick={() => onFloorPlanSize()}>
            <i className='right-floor-plan-fullscreen-button-img' />
          </div>
          <div
            className='right-floor-plan-button'
            style={{ visibility: props.currentFloorPlanIndex >= props.floorPlans.length - 1 ? 'hidden' : 'visible' }}
            onClick={() => props.slideFloorPlan(1)}
          >
            <i className='right-floor-plan-button-img' />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FloorPlan;
