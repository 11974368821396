import React, { useState } from 'react';
import './ModalShare.scss';
import oneActive from '../../images/modal/one-active.svg';
import oneInactive from '../../images/modal/one-inactive.svg';
import twoActive from '../../images/modal/two-active.svg';
import twoInactive from '../../images/modal/two-inactive.svg';
import CloseButton from '../../images/modal/cross.svg';
import { Trans } from '@lingui/react';
import InviteUser from './InviteUser';
import { IUserInvite, IYourDetails } from './interface';
import YourDetail from './YourDetail';
import { EnumNotificationType } from '../../utils/notification/notification';
import { createMeeting } from '../../services/liveMeetingServices';
import { ICreateMeeting } from '../../services/Interfaces';

export enum TabStateEnum {
  YourDetails = 1,
  SendInvite = 2,
}

interface IProps {
  openModalShare: () => void;
  toggleNotification: (type: EnumNotificationType, message?: string) => void;
  tourName: string;
  tourOwner: string;
}

const ModalShare: React.FC<IProps> = ({ openModalShare, toggleNotification, tourName, tourOwner }) => {
  const [tabState, setTabsState] = useState<TabStateEnum>(TabStateEnum.YourDetails);
  const [userInput, setUserInput] = useState<IYourDetails>();
  const [inviteUser, setInviteUser] = useState<IUserInvite>();
  const [meeting, setMeeting] = useState<ICreateMeeting>();

  const createMeetingAsync = async () => {
    if (!meeting) {
      try {
        const meetingUrl = window.location.href;
        const newMeeting = await createMeeting(meetingUrl, tourName, tourOwner);
        setMeeting(newMeeting);
        setTabsState(TabStateEnum.SendInvite);
      } catch (error) {
        console.log(error);
        toggleNotification(EnumNotificationType.Error, 'Ooops something went wrong - give it an other go');
      }
    } else {
      setTabsState(TabStateEnum.SendInvite);
    }
  };

  return (
    <div className='container-Modal'>
      <div className='modal-header'>
        <div className='title-modal'>
          <img className='img-align' src={tabState === TabStateEnum.YourDetails ? oneActive : oneInactive} alt='one-green' />
          <span className={tabState === TabStateEnum.YourDetails ? 'active-font' : 'inactive-font'}>
            <Trans id='Enter your details' />
          </span>
        </div>
        <div className='title-modal'>
          <img className='img-align' src={tabState === TabStateEnum.SendInvite ? twoActive : twoInactive} alt='two-grey' />
          <span className={tabState === TabStateEnum.SendInvite ? 'active-font' : 'inactive-font'}>
            <Trans id='Send the invite / copy link' />
          </span>
        </div>
        <button onClick={openModalShare} className='remove-default close-button'>
          <img className='cross-img' src={CloseButton} alt='cross' />
        </button>
      </div>
      <div className='modal-body'>
        <div
          style={{
            opacity: tabState === TabStateEnum.YourDetails ? 1 : 0,
            position: tabState === TabStateEnum.YourDetails ? 'relative' : 'absolute',
          }}
        >
          <YourDetail createMeetingAsync={createMeetingAsync} meeting={meeting} userInput={userInput} setUserInput={setUserInput} />
        </div>
        <div
          style={{
            opacity: tabState === TabStateEnum.SendInvite ? 1 : 0,
            position: tabState === TabStateEnum.SendInvite ? 'relative' : 'absolute',
          }}
        >
          <InviteUser
            toggleNotification={toggleNotification}
            meeting={meeting}
            userInput={userInput}
            setTabsState={setTabsState}
            inviteUser={inviteUser}
            setInviteUser={setInviteUser}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalShare;
