import { i18n } from '@lingui/core';
import React, { useState, useEffect } from 'react';
import { ReportCustomEvent } from '../../GoogleAnalyticsConfig';
import Player from '../../utils/SoundPlayer/SoundPlayer';
import './ControlsBar.scss';
import { EnumCategory, EnumEvent } from '../../GoogleAnalyticsTrackList';
import { IViewerPannellumPanoramaSound } from '../Viewer/Interfaces';

interface Props {
  isRatingToggle: boolean;
  currentRoomId: string;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onFullScreen: () => void;
  setIsRatingToggle: (isRatingToggle: boolean) => void;
  sounds?: IViewerPannellumPanoramaSound[] | null;
  isRatingsEnable: boolean;
}

type buttonStates = {
  disable: boolean | false;
  state: boolean | false;
};

type audioButtonState = {
  disable: boolean | false;
  state: boolean | false;
  audioUrl?: string;
  audioLoop?: boolean;
  audioPlay?: boolean;
};

/**
 * Control bar component
 * @param props
 * @returns Controls bar component
 * @category Component
 */
const ControlsBar = (props: Props): JSX.Element => {
  const [fullscreen, setFullscreen] = useState<buttonStates>({ disable: false, state: false });
  const [audio, setAudio] = useState<audioButtonState>({ disable: false, state: false });
  const [playAcross, setPlayAcross] = useState<boolean>(false);

  const handleSetFullscreen = () => {
    const fullscreenValue = !fullscreen.state;
    setFullscreen(prevState => ({
      ...prevState,
      state: fullscreenValue,
    }));

    props.onFullScreen();
  };

  useEffect(() => {
    if (!playAcross) {
      setAudio({ disable: false, state: false });
    }
    if (props && props.sounds) {
      let sound;
      const findPlayAcrossTour = props.sounds.find((sound: IViewerPannellumPanoramaSound) => sound.playAcrossTour === '1');
      if (findPlayAcrossTour) {
        sound = findPlayAcrossTour;
      } else {
        sound = props.sounds.find((sound: IViewerPannellumPanoramaSound) => sound.panoramaId === props.currentRoomId);
      }
      if (sound) {
        setPlayAcross(sound?.playAcrossTour === '1' ? true : false);
        setAudio({
          disable: false,
          state: true,
          audioLoop: Boolean(sound.audioLoop),
          audioPlay: Boolean(sound.audioPlay),
          audioUrl: sound.audioUrl,
        });
      }
    }
    // eslint-disable-next-line
  }, [props]);

  return (
    <div className='controls-bar'>
      {props.isRatingsEnable && (
        <div className='rating-button' onClick={() => props.setIsRatingToggle(!props.isRatingToggle)} title='Toggle ratings'>
          <i className='toggle-rating-icon' />
        </div>
      )}
      <div
        className='zoom-in'
        onClick={() => {
          props.onZoomIn();
          ReportCustomEvent(EnumCategory.Header, EnumEvent.ZoomIn);
        }}
        title={String(i18n.messages.control_bar_zoom_in)}
      >
        <i className='zoom-in-icon' />
      </div>
      <div
        className='zoom-out'
        onClick={() => {
          props.onZoomOut();
          ReportCustomEvent(EnumCategory.Header, EnumEvent.ZoomOut);
        }}
        title={String(i18n.messages.control_bar_zoom_out)}
      >
        <i className='zoom-out-icon' />
      </div>
      <div
        className={`fullscreen ${fullscreen.disable ? 'disable' : fullscreen.state ? 'active' : 'active'}`}
        onClick={() => {
          handleSetFullscreen();
          ReportCustomEvent(EnumCategory.Header, EnumEvent.EnterExitFullscreen);
        }}
        title={String(i18n.messages.control_bar_full_screen)}
      >
        <i className='fullscreen-icon' />
      </div>
      <div className='sound-btn-container' onClick={() => ReportCustomEvent(EnumCategory.Header, EnumEvent.EnableDisableAudio)}>
        {audio.state && audio.audioUrl ? (
          <Player url={audio.audioUrl} audioLoop={audio.audioLoop} audioPlay={audio.audioPlay} />
        ) : (
          <i className='sounds-icon-disable' />
        )}
      </div>
      {/* <div className='information' title={String(i18n.messages.control_bar_information)}>
        <i className='information-icon' />
      </div> */}
    </div>
  );
};

export default ControlsBar;
