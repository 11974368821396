import React, { useEffect, useState } from 'react';
import { GoogleAnalyticsInit, ReportCustomEvent } from '../../GoogleAnalyticsConfig';
import { useParams, useSearchParams } from 'react-router-dom';
import { Trans, TransRenderProps } from '@lingui/react';
import { usePasswordValidation } from '../../utils/custom-hooks/usePasswordValidation';
import { getStandaloneTour } from '../../services/tourServices';
import ProtectedViewerPage from '../../pages/ProtectedViewerPage';
import hidePasswordIcon from '../../images/protected-screen/password-hidden.svg';
import showPasswordIcon from '../../images/protected-screen/password-shown.svg';
import warning from '../../images/protected-screen/warning.svg';
import closeCross from '../../images/protected-screen/closeCross.svg';
import './ProtectedScreen.scss';
import { EnumCategory, EnumEvent } from '../../GoogleAnalyticsTrackList';
import { IViewerPannellumData, IViewerPannellumProtectedData } from '../Viewer/Interfaces';

interface IPassword {
  firstPassword: string;
}

interface Props {
  propertyName: string;
}

/**
 * ProtectedScreen component
 * @param props
 * @returns ProtectedScreen component
 * @category Component
 */
const ProtectedScreen = (props: Props): JSX.Element => {
  const [isTourData, setIsTourData] = useState(false);
  const [tourData, setTourData] = useState<IViewerPannellumData | IViewerPannellumProtectedData>();

  // URL params
  const { readCode, mode } = useParams<{ readCode: string; mode: string }>();

  // Rating component
  const [isRating, setIsRating] = useState(false);
  const [ratingToken, setRatingToken] = useState('');

  // Password state
  const [password, setPassword] = useState<IPassword>({
    firstPassword: '',
  });

  // Custom hook
  const [validLength] = usePasswordValidation({
    firstPassword: password.firstPassword,
    requiredLength: 1,
    lengthValidation: true,
  });

  // Handle password input
  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({ ...password, firstPassword: event.target.value });
  };

  // Hide and display passwords
  const [passwordShown, setPasswordShown] = useState<boolean>(false);

  // Disable form button
  const [buttonDisable, setButtonDisable] = useState<boolean>(true);

  // Display unAuthorized
  const [isError, setIsError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Unblock tour
   */
  const unblockTour = async () => {
    setIsError(false);
    setLoading(true);
    try {
      const response = await getTourData(readCode, password.firstPassword);
      if (response) {
        setTourData(response);
      }
    } catch (error) {
      console.log(error);
    }
    ReportCustomEvent(EnumCategory.PasswordModal, EnumEvent.UnlockVirtualTour);
  };

  useEffect(() => {
    if (tourData) {
      setIsTourData(true);
      // Set Rating permission
      const params = new URLSearchParams(window.location.search);
      const tokenIdFromParams = params.get('token');
      if (mode === 'annotation' && tokenIdFromParams) {
        setIsRating(true);
        setRatingToken(tokenIdFromParams);
      }
    }
    // eslint-disable-next-line
  }, [tourData]);

  /**
   * Show password
   */
  const showPassword = (): void => {
    setPasswordShown(!passwordShown);
  };

  // Validate passwords on change
  useEffect(() => {
    setButtonDisable(!validLength);
  }, [validLength]);

  // Get tour data
  const getTourData = async (readCode: string = '', password?: string) => {
    try {
      const checkParam = searchParams.get('redirect');
      const response = await getStandaloneTour(readCode, password);
      if (!checkParam && response?.dollhouseExist) {
        // redirect to dollhouse viewer
        const dollhouseViewerUrl = window.ENV.REACT_APP_3D_VIEWER_URL || process.env.REACT_APP_3D_VIEWER_URL;
        window.location.replace(`${dollhouseViewerUrl}?tour=${readCode}`);
        return;
      }
      GoogleAnalyticsInit();
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      if ((error as Error).message === 'Unauthorized') {
        setIsError(true);
      }
    }
  };

  /**
   * Handle service response
   * @returns page destination
   */
  const handleTourRedirection = () => {
    if (isTourData) {
      return <ProtectedViewerPage tourData={tourData as IViewerPannellumData} ratingPermission={isRating} ratingToken={ratingToken} />;
    }
  };

  const closeWarningModal = () => {
    setPassword({ firstPassword: '' });
    setIsError(false);
  };

  return (
    <React.Fragment>
      {!isTourData ? (
        <div className='password-protected-background'>
          <div className='password-protected'>
            <div className='password-protected-title-container'>
              <p>
                <Trans id='Unlock the virtual tour' />
              </p>
            </div>
            <div className='password-protected-content-container'>
              <div className='password-protected-info'>
                <p className='password-protected-text'>
                  <Trans
                    id='The tour <0>{propertyName}</0> is password protected'
                    values={{ propertyName: props.propertyName }}
                    components={{ 0: <b></b> }}
                  />
                </p>
                <p className='password-protected-text subtitle'>
                  <Trans id='Enter the password in the field below to see the virtual tour' />
                </p>
              </div>
              <div className='password-error-container'>
                <div className={isError ? 'password-error-box' : 'hidden'}>
                  <img className='password-error-warning-icon' src={warning} alt='Warning icon' />
                  <p className='password-error-text'>
                    <Trans id='Your password is incorrect. Please, try again!' />
                  </p>
                  <button className='btn-close-warning-modal' onClick={closeWarningModal}>
                    <img src={closeCross} alt='Close modal icon' />
                  </button>
                </div>
              </div>
              <form className='password-protected-form'>
                <div className='form-input-container'>
                  <Trans
                    id='Enter your password'
                    render={({ translation }: TransRenderProps) => (
                      <input
                        className={`password-protected-input ${isError ? 'input-error' : ''}`}
                        name='password'
                        type={passwordShown ? 'text' : 'password'}
                        placeholder={translation as string}
                        onChange={onPasswordChange}
                        value={password.firstPassword}
                      />
                    )}
                  />
                </div>

                <div className='unlock-tour-btn-container'>
                  <button
                    onClick={(e: React.MouseEvent): void => {
                      e.preventDefault();
                      showPassword();
                    }}
                    className='password-protected-show-password-icon'
                  >
                    <img
                      src={passwordShown ? showPasswordIcon : hidePasswordIcon}
                      alt='show-pwd'
                      className={isError ? 'eye-error' : 'eye-error'}
                    />
                  </button>
                  {loading ? (
                    <i className='fa fa-circle-o-notch fa-spin fa-2x fa-fw'></i>
                  ) : (
                    <button
                      className={`${buttonDisable ? 'btn-inactive' : 'btn-active'} btn-unlock-tour`}
                      onClick={(e: React.MouseEvent): void => {
                        e.preventDefault();
                        unblockTour();
                      }}
                      disabled={buttonDisable ? true : false}
                    >
                      <Trans id='Unlock the virtual tour' />
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        handleTourRedirection()
      )}
    </React.Fragment>
  );
};

export default ProtectedScreen;
