import { ClientApi } from '@vva/front';

const clientApi = new ClientApi();

interface IPropertyRating {
	tourValue: string;
}

interface IRoomRating {
	panoramaValue: string;
}

export const getPropertyRating = async (tourId: string, token: string): Promise<IPropertyRating> => {
	clientApi.setJwtToken(token);
	const data: unknown = await clientApi.getData(
		`${window.ENV.REACT_APP_OPENDATA_API_URL || process.env.REACT_APP_OPENDATA_API_URL}/tours/${tourId}/rating`
	);
	return data as IPropertyRating;
};

export const getRoomRating = async (panoramaId: string, token: string): Promise<IRoomRating> => {
	clientApi.setJwtToken(token);
	const data: unknown = await clientApi.getData(
		`${window.ENV.REACT_APP_OPENDATA_API_URL || process.env.REACT_APP_OPENDATA_API_URL}/panoramas/${panoramaId}/rating`
	);
	return data as IRoomRating;
};

export const createPropertyRating = async (tourId: string, rating: number, token: string): Promise<void> => {
	clientApi.setJwtToken(token);
	await clientApi.postData(
		`${window.ENV.REACT_APP_OPENDATA_API_URL || process.env.REACT_APP_OPENDATA_API_URL}/tours/${tourId}/rating`, {
		rating
	}
	);
};

export const createRoomRating = async (tourId: string, panoramaId: string, rating: number, token: string): Promise<void> => {
	clientApi.setJwtToken(token);
	await clientApi.postData(
		`${window.ENV.REACT_APP_OPENDATA_API_URL || process.env.REACT_APP_OPENDATA_API_URL}/panoramas/${panoramaId}/rating`, {
		tourId,
		rating
	}
	);
};

export const deletePropertyRating = async (tourId: string, token: string): Promise<void> => {
	clientApi.setJwtToken(token);
	await clientApi.postData(
		`${window.ENV.REACT_APP_OPENDATA_API_URL || process.env.REACT_APP_OPENDATA_API_URL}/tours/${tourId}/rating`, {
		rating: null,
	}
	);
};

export const deleteRoomRating = async (tourId: string, panoramaId: string, token: string): Promise<void> => {
	clientApi.setJwtToken(token);
	await clientApi.postData(
		`${window.ENV.REACT_APP_OPENDATA_API_URL || process.env.REACT_APP_OPENDATA_API_URL}/panoramas/${panoramaId}/rating`, {
		tourId,
		rating: null,
	}
	);
};