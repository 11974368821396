import * as EmailValidator from 'email-validator';
import { IYourDetails } from "./interface";


export const isEmail = (email: string): boolean => {
    if (email !== '' && EmailValidator.validate(email)) {
        return true;
    } else {
        return false;
    }
};

export const isValidFullName = (fullName: string) => {
    return fullName !== '' && fullName?.length > 1 ? true : false;
};


export const validateInputsTours = (userInput?: IYourDetails) => {
    if (
        userInput &&
        isEmail(userInput?.email || '') &&
        isValidFullName(userInput.firstName || '') &&
        isValidFullName(userInput.lastName || '') &&
        userInput.phoneNumber !== '' &&
        userInput.token
    ) {
        return true;
    } else {
        return false;
    }
};

