import { Trans } from '@lingui/react';
import React from 'react';
import { IBranding } from '../Viewer/Interfaces';

import './ContactAgent.scss';

interface IProps {
  showModalAgent: boolean;
  brandingOptions: IBranding;
}

const ContactAgent: React.FC<IProps> = ({ showModalAgent, brandingOptions }) => {
  return (
    <div style={{ right: showModalAgent ? '0' : '-280px' }} className='modal-contact-agent'>
      <img className='logo-agent' src={brandingOptions.brandUrl} alt='brand-logo' />
      <div className='name-agent'>{brandingOptions.brandName}</div>
      <div className='adress-property'>{brandingOptions.brandAddress}</div>
      <a href={`mailto: ${brandingOptions.brandEmail}`}>
        <div className='contact-agents'>
          <Trans id='Contact the agent' />
        </div>
      </a>
    </div>
  );
};

export default ContactAgent;
