import { ClientApi } from '@vva/front';

export interface ILeadsGeneration {
    tourId: string,
    email?: string,
    fullName?: string,
    phoneNumber?: string,
    leadType: number
}

const clientApi = new ClientApi();

// conditional window ENV for testing with jest
clientApi.setHeaders({
    'X-Api-Key':
        (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY
});

/**
 * Create leads generation for Tour
 * @param form 
 * @returns {ILeadsGeneration}
 */
export const createLeadsGeneration = async (form: ILeadsGeneration): Promise<ILeadsGeneration> => {
    const body = form ? form : {};
    const response = await clientApi.postData(
        `${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/leadsGenerations`, body
    );
    return response.data;
};