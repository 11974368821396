import { ClientApi } from '@vva/front';
import { IPanoramaLog, ITourLog } from './Interfaces';

const clientApi = new ClientApi();

// conditional window ENV for testing with jest
clientApi.setHeaders({
    'X-Api-Key':
        (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY
});

/**
 * Create panorama log
 * @param form 
 * @returns response
 */
export const createPanoramaLog = async (userId: string, tourId: string, panoramaId: string, refererUrl: string): Promise<IPanoramaLog> => {
    const body: IPanoramaLog = {
        userId,
        tourId,
        panoramaId,
        refererUrl
    };
    const response = await clientApi.postData(
        `${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramaLogs`, body
    );
    return response.data;
};

/**
 * Create tour log
 * @param form 
 * @returns response
 */
export const createTourLog = async (userId: string, tourId: string, refererUrl: string): Promise<ITourLog> => {
    const body: ITourLog = {
        userId,
        tourId,
        refererUrl
    };
    const response = await clientApi.postData(
        `${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/panoramaLogs/tour`, body
    );
    return response.data;
};