import React, { useEffect, useState } from 'react';
import { IFormValidation, ILeadGenerationForm } from './interfaces';
import { useLeadsGenerationValidation } from './useLeadsGenerationValidation';
import './LeadGenerationForm.scss';
import { Trans, TransRenderProps } from '@lingui/react';
import { EnumCategory, EnumEvent } from '../../GoogleAnalyticsTrackList';
import { ReportCustomEvent } from '../../GoogleAnalyticsConfig';
import { ILeadGenerationConfig } from '../Viewer/Interfaces';

interface Props {
  config?: ILeadGenerationConfig;
  isLoading: boolean;
  submitLeadGeneration: (form: ILeadGenerationForm) => void;
}

/**
 * Lead Generation Compulsory form component
 * @param props
 * @returns Lead Generation Compulsory form component
 * @category Component
 */
const LeadGenerationCompulsory = (props: Props): JSX.Element => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [leadGenerationForm, setLeadGenerationForm] = useState<ILeadGenerationForm>({
    email: '',
    fullName: '',
    phoneNumber: '',
  });

  /**
   * On full name change handler event
   * @param event
   */
  const onFullNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(event.target.value);
    setLeadGenerationForm(prevState => ({
      ...prevState,
      fullName: event.target.value,
    }));
  };

  /**
   * On email change handler event
   * @param event
   */
  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setLeadGenerationForm(prevState => ({
      ...prevState,
      email: event.target.value,
    }));
  };

  /**
   * On phone number change handler event
   * @param event
   */
  const onPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
    setLeadGenerationForm(prevState => ({
      ...prevState,
      phoneNumber: event.target.value,
    }));
  };

  /**
   * Validation schema
   */
  let validationSchema: IFormValidation = {
    fullNameValidation: false,
    phoneNumberValidation: false,
    emailValidation: false,
  };

  /**
   * If full name is required
   */
  if (props.config && props.config.enableName) {
    validationSchema.fullName = fullName;
    validationSchema.fullNameValidation = true;
  }

  /**
   * If email is required
   */
  if (props.config && props.config.enableEmailAddress) {
    validationSchema.email = email;
    validationSchema.emailValidation = true;
  }

  // If full name is required
  if (props.config && props.config.enablePhoneNumber) {
    validationSchema.phoneNumber = phoneNumber;
    validationSchema.phoneNumberValidation = true;
  }

  /**
   * Custom lead generation form validation
   */
  const [validEmail, validFullName, validPhoneNumber] = useLeadsGenerationValidation(validationSchema);

  const [buttonDisable, setButtonDisable] = useState(false);

  /**
   * Form Validation
   * @param fullNameValidation
   * @param emailValidation
   * @param phoneNumberValidation
   * @returns {boolean} valid state
   */
  const checkParameters = (fullNameValidation: boolean, emailValidation: boolean, phoneNumberValidation: boolean) => {
    const isValid = (currentValue: boolean) => currentValue === true;
    let params = [];
    if (props.config && props.config.enableName) {
      params.push(fullNameValidation);
    }

    if (props.config && props.config.enableEmailAddress) {
      params.push(emailValidation);
    }

    if (props.config && props.config.enablePhoneNumber) {
      params.push(phoneNumberValidation);
    }
    return params.every(isValid);
  };

  /**
   * Form validation check
   */
  useEffect(() => {
    setButtonDisable(!checkParameters(validFullName, validEmail, validPhoneNumber) ? true : false);
    // eslint-disable-next-line
  }, [validFullName, validEmail, validPhoneNumber]);

  return (
    <div className='lead-generation-container'>
      <form className='lead-generation-form'>
        {props.config && props.config.enableName ? (
          <div className='lead-generation-inputs-container'>
            <label className='lead-generation-label'>
              <Trans id='Name' />
            </label>
            <Trans
              id='Name'
              render={({ translation }: TransRenderProps) => (
                <input
                  className='lead-generation-input-name'
                  name='name'
                  type={'text'}
                  placeholder={translation as string}
                  onChange={onFullNameChange}
                />
              )}
            />
            <span className={!validFullName && fullName !== '' ? 'error-message-on' : 'error-message-off'}>
              <Trans id='You must type in a valid name' />
            </span>
          </div>
        ) : null}
        {props.config && props.config.enableEmailAddress ? (
          <div className='lead-generation-inputs-container'>
            <label className='lead-generation-label'>
              <Trans id='Email address' />
            </label>
            <Trans
              id='Email address'
              render={({ translation }: TransRenderProps) => (
                <input
                  className='lead-generation-input-mail'
                  name='Email'
                  type={'text'}
                  placeholder={translation as string}
                  onChange={onEmailChange}
                />
              )}
            />
            <span className={!validEmail && email !== '' ? 'error-message-on' : 'error-message-off'}>
              <Trans id='You must type in a valid email' />
            </span>
          </div>
        ) : null}
        {props.config && props.config.enablePhoneNumber ? (
          <div className='lead-generation-inputs-container'>
            <label className='lead-generation-label'>
              <Trans id='Phone number' />
            </label>
            <Trans
              id='Phone number'
              render={({ translation }: TransRenderProps) => (
                <input
                  className='lead-generation-input-phone'
                  name='Phone'
                  type={'text'}
                  placeholder={translation as string}
                  onChange={onPhoneNumber}
                />
              )}
            />
            <span className={!validPhoneNumber && phoneNumber !== '' ? 'error-message-on' : 'error-message-off'}>
              <Trans id='You must type in a valid phone number' />
            </span>
          </div>
        ) : null}
        <div className='lead-generation-btn-container'>
          <button
            className={`${buttonDisable ? 'btn-lead-inactive' : 'btn-lead-active button-hover'} btn-lead-compulsory`}
            onClick={(e: React.MouseEvent): void => {
              e.preventDefault();
              props.submitLeadGeneration(leadGenerationForm);
              ReportCustomEvent(EnumCategory.FormSubmitButtonCompulsory, EnumEvent.SendRequestCompulsory);
            }}
            disabled={buttonDisable ? true : false}
          >
            <Trans id='Send Request and see the virtual tour' />
            {props.isLoading && <i id='loading-animation-compulsory' className='fa fa-circle-o-notch fa-spin fa-2x fa-fw'></i>}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LeadGenerationCompulsory;
