import { defineMessage } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { en, fr, ja, es, it } from 'make-plural/plurals';
import { messages as enMessages } from '../../locales/en/messages.js'; // English
import { messages as esMessages } from '../../locales/es/messages.js'; // Spanish
import { messages as itMessages } from '../../locales/it/messages.js'; // Italian
import { messages as jaMessages } from '../../locales/ja/messages.js';
import { messages as frMessages } from '../../locales/fr/messages.js';
import { ValidationUtils } from '../validation-utils/validation-utils';

/**
 * Enum use for detect browser
 */
export enum EnumDetectLanguage {
  en = 'en',
  fr = 'fr',
  ja = 'ja',
  it = 'it',
  es = 'es',
}

/**
 * Set language from code
 * @param language 
 * @returns set language configuration
 */
export const setLanguageFromCode = (language: string = 'en') => {
  const languageCode = language.split('-');
  const code = ValidationUtils.isArrayNotEmpty(languageCode) ? languageCode[0] : 'en';
  switch (code) {
    case EnumDetectLanguage.en:
      return { code: 'en', messages: enMessages, plurals: en };
    case EnumDetectLanguage.fr:
      return { code: 'fr', messages: frMessages, plurals: fr };
    case EnumDetectLanguage.it:
      return { code: 'it', messages: itMessages, plurals: it };
    case EnumDetectLanguage.es:
      return { code: 'es', messages: esMessages, plurals: es };
    case EnumDetectLanguage.ja:
      return { code: 'ja', messages: jaMessages, plurals: ja };
    default:
      return { code: 'en', messages: enMessages, plurals: en };
  }
};

/**
 * Translate for variable
 * @param id
 * @returns {id} - Translate Id outside Html
 */
export const linguiTranslateToString = (id: string) => {
  return i18n._(defineMessage({ message: id }));
};

/**
 * Translate for array
 * @param values
 * @returns {string[]} - Array of tranlated Id
 */
export const linguiTranslateIdToStringArray = (values: string[]) => {
  return values.map(value => i18n._(defineMessage({ message: value })));
};
