import React, { useState } from 'react';
import Pattern from '../../images/modal/pattern.svg';
import Confirmation from '../../images/modal/confirmation.svg';
import { Trans } from '@lingui/react';
import { resentJoinMeetingEmail } from '../../services/liveMeetingServices';
import { useLocation } from 'react-router-dom';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notification/notification';

const ConfirmationSent = () => {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [clicked, setClicked] = useState<boolean>(false);
  const location = useLocation();
  const { userId, meetingCode, email } = location.state;

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    setTypeNotification(type);
    setNotificationMessage(message || 'There was an error please try again');
    setShowNotification(true);
  };

  const resentJoinMeetingEmailAsync = async () => {
    try {
      await resentJoinMeetingEmail(meetingCode, userId);
      toggleNotification(EnumNotificationType.Success, 'Email been succesfully resend');
      setClicked(true);
    } catch (error) {
      console.log(error);
      toggleNotification(EnumNotificationType.Error, 'Ooops something went wrong - give it an other go');
    }
  };

  return (
    <>
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
        />
      )}
      <div className='container-background'>
        <img className='pattern-img' src={Pattern} alt='background' />
        <div className='container-box'>
          <img className='confirmation-img' src={Confirmation} alt='illustration' />
          <div className='confirmation-text-container'>
            <div className='title-confirmation'>
              <Trans id='The confirmation email has just been sent!' />
            </div>
            <div className='first-subtext'>
              <Trans id='We have sent an email to access the Live-Sharing to:' />
            </div>
            <div className='email-confirmation'>{email}</div>
            <div className='second-subtext'>
              <Trans id='Click the button in the email to start the live sharing of this property ' />
            </div>
            <div className='havent-received'>
              <Trans id='Haven’t received it? Click below to resend it' />
            </div>
            {!clicked ? (
              <div className='button-resend-the-email' onClick={() => resentJoinMeetingEmailAsync()}>
                <Trans id='Resend the email' />
              </div>
            ) : (
              <div className='button-pressed-resend'>
                <Trans id='Sent' />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationSent;
