export class ImageUtils {
    public static checkImageOrientation(width: number, height: number) {

        let orientation;

        if (width > height) {
            orientation = 'landscape';
        } else if (width < height) {
            orientation = 'portrait';
        } else {
            orientation = 'even';
        }

        return orientation;

    }
}