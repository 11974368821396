import './PanoramaSlider.scss';
import React, { useEffect, useRef, useState } from 'react';
import { IViewerPannellumThumbnail } from '../../Viewer/Interfaces';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../GoogleAnalyticsTrackList';

interface Props {
  onChangeScene: (sceneId: string, targetYaw: number) => void;
  thumbNailsList: IViewerPannellumThumbnail[];
  onDisplay: boolean;
  currentRoomId: string;
}

const PanoramaSlider = (props: Props): JSX.Element => {
  const imageList = props.thumbNailsList;
  let mappedRefs = useRef<HTMLDivElement[]>([]);
  const sliderRef = useRef<HTMLUListElement>(null);
  const scrollIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [menuTriggered, setMenuTriggered] = useState<boolean>();

  useEffect(() => {
    if (props.onDisplay) {
      setMenuTriggered(true);
    }
  }, [props.onDisplay]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (props.currentRoomId && mappedRefs.current && mappedRefs.current.length > 0) {
      const findElementId = mappedRefs.current.findIndex((el: HTMLDivElement) => el.id === props.currentRoomId);
      if (findElementId >= 0) {
        const disableSlider = params.get('disableSlider');
        if (!disableSlider) {
          mappedRefs.current[findElementId].scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, [props.currentRoomId]);

  const scrollLeft = () => {
    if (sliderRef.current) {
      const scrollOffset = -400;
      const newScrollPosition = sliderRef.current.scrollLeft + scrollOffset;
      if (newScrollPosition < 0) {
        sliderRef.current.scrollLeft = 0;
      } else {
        sliderRef.current.scrollLeft = newScrollPosition;
      }
    }
  };

  const scrollRight = () => {
    const scrollOffset = 400;
    if (sliderRef.current) {
      const maxScrollLeft = sliderRef.current.scrollWidth - sliderRef.current.clientWidth;
      const newScrollPosition = sliderRef.current.scrollLeft + scrollOffset;
      if (newScrollPosition > maxScrollLeft) {
        sliderRef.current.scrollLeft = maxScrollLeft;
      } else {
        sliderRef.current.scrollLeft = newScrollPosition;
      }
    }
  };

  const startScrollingLeft = () => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }
    scrollIntervalRef.current = setInterval(scrollLeft, 250);
  };

  const startScrollingRight = () => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }
    scrollIntervalRef.current = setInterval(scrollRight, 250);
  };

  const stopScrolling = () => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }
  };


  const addRefs = (ref: HTMLDivElement) => {
    if (ref && !mappedRefs.current.includes(ref)) {
      mappedRefs.current.push(ref);
    }
  };

  const items = imageList.map((image, index) => {
    return (
      <div ref={addRefs} id={image.id} key={index}>
        <li
          id='thumbnail-group'
          className='thumbnail-group'
          onClick={() => {
            // Google analytics event
            ReportCustomEvent(EnumCategory.TourNavigation, EnumEvent.ThumbnailsNavigation);
            props.onChangeScene(image.sceneId, image.targetYaw);
          }}
        >
          <div className={props.currentRoomId === image.sceneId ? 'thumbnail-image-active-dot' : 'thumbnail-image-inactive-dot'}></div>
          <img loading='lazy' src={image.url} alt={image.title} className='thumbnail-image'></img>
          <div className='thumbnail-text' data-title={image.title} data-name={image.title}></div>
          <div className={props.currentRoomId === image.sceneId ? 'thumbnail-image-active' : 'thumbnail-image-inactive'}></div>
        </li>
      </div>
    );
  });

  return (
    <React.Fragment>
      <div
        className={`thumbnails-slider  ${props.onDisplay ? 'up' : props.onDisplay === false && menuTriggered !== undefined ? 'down' : ''}`}
      >
        <div className='left-slider-button-group' onMouseDown={startScrollingLeft} onMouseUp={stopScrolling} onMouseLeave={stopScrolling} onClick={() => scrollLeft()}>
          <div className='left-slider-button'>
            <div className='left-slider-button-img' />
          </div>
        </div>
        <div id='slider-bar' className={'slider-bar'}>
          <ul ref={sliderRef} className='scene-wrapper'>
            {items}
          </ul>
        </div>
        <div className='right-slider-button-group'>
          <div className='right-slider-button' onMouseDown={startScrollingRight} onMouseUp={stopScrolling} onMouseLeave={stopScrolling} onClick={() => scrollRight()}>
            <div className='right-slider-button-img' />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PanoramaSlider;
