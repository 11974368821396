import { Trans } from '@lingui/react';
import { useEffect, useRef, useState } from 'react';
import { IButtonStateBottom, IUserInvite, IYourDetails } from './interface';
import { isEmail } from './utils';
import './ModalShare.scss';
import { ICreateMeeting } from '../../services/Interfaces';
import { createOrganiser, sendInviteeEmail } from '../../services/liveMeetingServices';
import { EnumNotificationType } from '../../utils/notification/notification';
import { useNavigate } from 'react-router-dom';
import tick from '../../images/modal/tick.svg';

interface IProps {
  inviteUser?: IUserInvite;
  setInviteUser: (state: IUserInvite) => void;
  userInput?: IYourDetails;
  meeting?: ICreateMeeting;
  toggleNotification: (type: EnumNotificationType, message?: string) => void;
}

const InviteUserMobile: React.FC<IProps> = ({ inviteUser, setInviteUser, userInput, meeting, toggleNotification }) => {
  const [buttonState, setButtonState] = useState<IButtonStateBottom>({
    email: false,
    link: false,
  });
  const [liveTourUrl, setLiveTourUrl] = useState<string>('');
  const navigate = useNavigate();
  const copyLinkAreaRef = useRef<any>(null);

  useEffect(() => {
    if (meeting?.code) {
      const url = `${window.ENV.REACT_APP_LIVE_FRONT_URL || process.env.REACT_APP_LIVE_FRONT_URL}/${meeting.code}`;
      setLiveTourUrl(url);
    }
  }, [meeting]);

  const getInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInviteUser({ ...inviteUser, [e.target.name]: e.target.value });
  };

  const createOrganiserAsyncMobile = async () => {
    if (userInput && meeting) {
      try {
        const newOrganiser = await createOrganiser(meeting.code, userInput);
        navigate('/email-confirmation-mobile', {
          state: { userId: newOrganiser?.id, meetingCode: meeting?.code, email: newOrganiser.email },
        });
      } catch (error) {
        console.log(error);
        toggleNotification(EnumNotificationType.Error, 'Ooops something went wrong - give it an other go');
      }
    }
  };

  const sendEmailAsync = async () => {
    if (isEmail(inviteUser?.email || '')) {
      if (inviteUser?.email && meeting) {
        try {
          await sendInviteeEmail(meeting?.code, inviteUser?.email);
          setButtonState({ ...buttonState, email: true });
          setTimeout(() => {
            setButtonState({ ...buttonState, email: false });
          }, 4000);
          setInviteUser({ ...inviteUser, email: '' });
        } catch (error) {
          toggleNotification(EnumNotificationType.Error, 'Ooops something went wrong - give it an other go');
          console.log(error);
        }
      }
    }
  };

  const copyingEmailAsync = async () => {
    copyLinkAreaRef.current?.select();
    document.execCommand('copy');
    setButtonState({ ...buttonState, link: true });
  };

  return (
    <div className='send-copy-container-mobile'>
      <div className='title-mobile'>
        <p className='title-send-invite-mobile'>
          <Trans id='Send the invite OR copy the link' />
        </p>
      </div>
      <div className='send-copy-text-area-mobile'>
        <p className='text-tour-detail-invite-mobile'>
          <Trans id='Send the invite' />
        </p>
        <p className='subtext-tour-detail-invite-mobile'>
          <Trans id='Enter the email address of the person you want to invite' />
        </p>
      </div>
      <div className='send-copy-input-area-mobile'>
        <div className='send-copy-input'>
          <div
            className={`send-copy-input-labels ${
              inviteUser?.email === undefined || inviteUser.email === ''
                ? 'send-copy-input-labels '
                : isEmail(inviteUser?.email || '')
                ? ''
                : 'send-copy-input-input-invalid'
            }`}
          >
            <Trans id='Email' render={({ translation }) => <label className='create-tour-labels'>{translation}</label>} />
          </div>
          <input
            placeholder='jess.smith@gmail.com'
            id='email'
            name='email'
            value={inviteUser?.email || ''}
            className={`general-field-input-text-mobile  ${
              inviteUser?.email === undefined || inviteUser.email === ''
                ? 'general-field-input-text-mobile'
                : isEmail(inviteUser?.email || '')
                ? 'valid-input'
                : 'invalid-input'
            }`}
            type='text'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => getInput(e)}
          />
        </div>
        <div className='send-copy-input'>
          <div className='button-send-container-mobile'>
            {!buttonState.email ? (
              <div className='button-send-email-mobile' onClick={() => sendEmailAsync()}>
                <Trans id='Send' />
              </div>
            ) : (
              <div className='button-sent-mobile'>
                <img src={tick} alt='icon' />
                <Trans id='Sent' />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='line-container'>
        <div className='line'></div>
        <p className='or-text'>or</p>
        <div className='line'></div>
      </div>
      <div className='send-copy-text-area-link'>
        <p className='text-tour-detail'>
          <Trans id='Copy the link' />
        </p>
        <p className='subtext-send-copy'>
          <Trans id='Copy the link of the invite and share it with the person / people you would like to' />
        </p>
        <p className='subtext-send-copy'>
          <Trans id='invite' />
        </p>
      </div>
      <div className='send-copy-input-area-mobile'>
        <div className='send-copy-input'>
          <div className='send-copy-input-labels'></div>
          <input
            placeholder='Copy the link'
            id='link'
            name='link'
            ref={copyLinkAreaRef}
            readOnly
            value={liveTourUrl}
            className={`general-field-input-text-mobile`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => getInput(e)}
            type='text'
          />
        </div>
        <div className='send-copy-input'>
          <div className='button-send-container-mobile'>
            {!buttonState.link ? (
              <div className='button-send-link-mobile' onClick={() => copyingEmailAsync()}>
                <Trans id='Copy' />
              </div>
            ) : (
              <div className='button-sent-mobile'>
                <img src={tick} alt='icon' />
                <Trans id='Copied' />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='container-button-copy-link-mobile'>
        <div className={'button-confirm-mobile'} onClick={() => createOrganiserAsyncMobile()}>
          <Trans id='Confirm your email' />
        </div>
      </div>
    </div>
  );
};

export default InviteUserMobile;
